import React, { useCallback } from 'react'
import styled from 'styled-components'
import { appActions, lastActionSelector } from 'src/redux/ducks/app'
import { Button } from 'src/components'
import { useSelector, useDispatch } from 'react-redux'
import { GameActions } from 'src/redux/ducks/app'
import { Scenario, mapActions } from 'src/redux/ducks/map'
import { Modals } from 'src/types/modals'
import history from 'src/history'

const BackButtonStyled = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`

export const BackButton: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const lastAction = useSelector((state) => lastActionSelector(state))

  const onClick = useCallback(() => {
    if (
      lastAction === GameActions.taxiGame ||
      lastAction === Scenario.goHomeByBus ||
      lastAction === Scenario.goHomeByCar ||
      lastAction === Scenario.goHomeByTaxi ||
      lastAction === Scenario.goHomeOnFoot
    ) {
      dispatch(appActions.setLastAction(null))
      dispatch(appActions.appModalOpen(Modals.visit))
    }

    if (
      lastAction === GameActions.foodGame ||
      lastAction === Scenario.goMarket
    ) {
      history.push('/city/home/kitchen')
    }

    if (
      lastAction === Scenario.goPharmacy ||
      lastAction === GameActions.medicineGame
    ) {
      history.push('/city/home/bathroom')
    }

    dispatch(mapActions.removeScenario())
  }, [lastAction])

  if (!lastAction) return null

  return (
    <BackButtonStyled>
      <Button onClick={onClick}>Вернуться к выбору</Button>
    </BackButtonStyled>
  )
}
