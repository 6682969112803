import React, { useCallback } from 'react'
import { Button, Title, Text } from 'src/components'
import { Styled } from './styles'
import { useDispatch } from 'react-redux'
import { appActions } from 'src/redux/ducks/app'
import { Modals } from 'src/types/modals'
import { ITEMS } from './mocks'
import { Item } from './types'

type MusicProps = {
  onClose: VoidFunction
}

export const Music: React.FC<MusicProps> = (props) => {
  const dispatch = useDispatch()

  const handleButtonClick = useCallback(() => {
    dispatch(appActions.appModalOpen(Modals.sound))
  }, [])

  return (
    <Styled.Wrapper>
      <Styled.Title>
        <Title type="h1">Музыка</Title>
      </Styled.Title>

      <Styled.List>
        {ITEMS.map((item: Item) => (
          <Styled.Item.Wrapper key={item.title}>
            <Styled.Item.Image>
              <img src="https://via.placeholder.com/48" />
            </Styled.Item.Image>
            <Styled.Item.Content>
              <Styled.Item.Title>
                <Text type="body1">{item.title}</Text>
              </Styled.Item.Title>
              <Styled.Item.Subtitle>
                <Text type="body2">{item.subtitle}</Text>
              </Styled.Item.Subtitle>
            </Styled.Item.Content>
            <Styled.Item.Duration>
              <Text type="body3">{item.duration}</Text>
            </Styled.Item.Duration>
          </Styled.Item.Wrapper>
        ))}
      </Styled.List>

      <Button onClick={handleButtonClick}>Расширить список композиций</Button>
    </Styled.Wrapper>
  )
}
