import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  overflow: hidden;

  .container {
    min-height: 100vh;
  }
`

const Content = styled.div`
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 361px;

  margin: 0 auto;

  padding-top: 37px;
  padding-bottom: 55px;

  text-align: center;

  @media (max-height: 410px), (max-width: 700px) {
    max-width: 250px;
  }
`

const Description = styled.div`
  max-width: 258px;
  margin-bottom: 140px;

  @media (max-height: 410px), (max-width: 700px) {
    margin-bottom: 20px;
  }
`

const ImageLeft = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translateX(-213px) translateY(30px);
  pointer-events: none;

  @media (max-height: 410px), (max-width: 700px) {
    transform: translate(-50%, -50%) translateX(-204px) translateY(15px);
  }
`

const ImageRight = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 430px;
  transform: translate(-50%, -50%) translateX(181px) translateY(40px);
  pointer-events: none;

  @media (max-height: 410px), (max-width: 700px) {
    transform: translate(-50%, -50%) translateX(161px) translateY(25px);
  }
`

const Button = styled.div`
  button {
    min-width: 200px;
  }
`

export const Styled = {
  Wrapper,
  Content,
  Description,
  ImageLeft,
  ImageRight,
  Button,
}
