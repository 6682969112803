import AntButton from 'antd/lib/button/index'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { ButtonProps } from './types'

export const ButtonStyled = styled(AntButton)<ButtonProps>`
  min-width: 272px;
  height: 40px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 5px 30px;

  font-size: 16px;
  line-height: 21px;
  font-weight: 600;

  border: none;
  border-radius: 8px;

  background: ${colors.GRADIENT_DUOTONE};
  color: ${colors.WHITE} !important;

  &:hover,
  &:active,
  &:focus,
  &:disabled {
    border: none !important;
    background: ${colors.GREEN} !important;
    color: ${colors.WHITE} !important;
    transition: none;
  }

  &:disabled {
    opacity: 0.5;
  }

  p {
    margin-bottom: 0;
  }

  ,
  a {
    color: inherit;
  }
`
