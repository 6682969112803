import React from 'react'
import logo from './logo.svg'

export const Logo: React.FC<{}> = () => {
  return (
    <div style={{ marginBottom: 32 }}>
      <img src={logo} alt="Сбер" />
    </div>
  )
}
