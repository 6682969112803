import React from 'react'
import { CrossButton } from 'src/components'
import { Styled } from './styles'

type HeaderProps = {
  onClose: VoidFunction
}

export const Header: React.FC<HeaderProps> = (props) => {
  const { onClose } = props

  return (
    <Styled.Wrapper>
      {props.children}
      <Styled.CloseButton>
        <CrossButton onClick={onClose} />
      </Styled.CloseButton>
    </Styled.Wrapper>
  )
}
