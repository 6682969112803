import React from 'react'
import { Styled } from './styles'
import refresh from './refresh.svg'
import arrowLeft from 'src/shared/img/arrow-left.svg'
import arrowRight from 'src/shared/img/arrow-right.svg'
import { Icons } from 'src/types'

const ICONS = {
  [Icons.refresh]: refresh,
  [Icons.arrowLeft]: arrowLeft,
  [Icons.arrowRight]: arrowRight,
}

type ButtonWithIconProps = {
  onClick: VoidFunction
  iconName: Icons
}

export const ButtonWithIcon: React.FC<ButtonWithIconProps> = (props) => {
  const { iconName } = props

  return (
    <Styled.Button {...props}>
      <img src={ICONS[iconName]} />
    </Styled.Button>
  )
}
