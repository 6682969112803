import styled from 'styled-components'
import { colors } from 'src/styles/colors'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const Loader = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${colors.SNOW};
`

export const Styled = {
  Wrapper,
  Loader,
}
