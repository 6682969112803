import styled from 'styled-components'
import { textShadow } from 'styles/text'
import { colors } from 'styles/colors'

const Wrapper = styled.div``

const Title = styled.div`
  max-width: 315px;
  margin: 0 auto 18px;

  ${textShadow(colors.GREEN)}
`

const Consultant = styled.div`
  margin-bottom: 18px;
  text-align: center;
`

const ConsultantImage = styled.img`
  max-width: 244px;
`

export const Styled = {
  Wrapper,
  Title,
  Consultant,
  ConsultantImage,
}
