import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { appActions, heroSelector, appModalSelector } from 'src/redux/ducks/app'
import { Styled } from './styles'
import { Modals } from 'src/types'
import {
  WelcomeKitchen,
  WelcomeBathroom,
  WelcomeLivingRoom,
  WelcomeBedroom,
} from './components'

export const WelcomeModal: React.FC<any> = (props) => {
  const dispatch = useDispatch()
  const hero = useSelector((state) => heroSelector(state))
  const appModal = useSelector((state) => appModalSelector(state))

  const closeModal = useCallback(() => {
    dispatch(appActions.appModalClose())
  }, [])

  return (
    <Styled.Wrapper hero={hero}>
      <Styled.Content>
        {appModal.name === Modals.welcomeKitchen && (
          <WelcomeKitchen closeModal={closeModal} />
        )}

        {appModal.name === Modals.welcomeBathroom && (
          <WelcomeBathroom closeModal={closeModal} />
        )}

        {appModal.name === Modals.welcomeLivingRoom && (
          <WelcomeLivingRoom closeModal={closeModal} />
        )}

        {appModal.name === Modals.welcomeBedroom && (
          <WelcomeBedroom closeModal={closeModal} />
        )}

        {/* play food */}

        {/* play pharmacy */}

        {/* living room */}
        {/*  */}

        {/* play okko */}
        {/* Онлайн-кинотеатр - это просто!

        Посмотреть кино в онлайн-кинотеатре
        «Okko» так же легко, как сыграть в игру. */}

        {/* <Styled.TextWithIcon>
          <Text type="body2">
            Ты можешь отправиться ко мне, нажав на кнопку{' '}
            <Text type="body1">«Домой»</Text>
          </Text>
          <img src={home} />
        </Styled.TextWithIcon>
        <Styled.TextWithIcon>
          <Text type="body2">
            Все твои достижения будут собраны в разделе{' '}
            <Text type="body1">«Подарки»</Text>
          </Text>
          <img src={benefits} />
        </Styled.TextWithIcon> */}
      </Styled.Content>
    </Styled.Wrapper>
  )
}
