import styled from 'styled-components'
import { colors } from 'src/styles/colors'

export const LoaderStyled = styled.div`
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(${colors.DARK_RGB}, 0.6);
`
