import { createSlice } from '@reduxjs/toolkit'
import { MapInitialState } from './'

const initialState: MapInitialState = {
  scenario: null,
}

const MapSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    activateScenario: (state, action) => ({
      ...state,
      scenario: action.payload,
    }),
    removeScenario: (state) => ({
      ...state,
      scenario: null,
    }),
  },
  extraReducers: {},
})

export const mapReducer = MapSlice.reducer
export const mapActions = MapSlice.actions
