import React from 'react'
import { connect } from 'react-redux'
import { appActions } from 'src/redux/ducks/app'
import { PlayerState, GameState } from './types'

import { EndGameModal, WelcomeModal } from './components'

import { ProgressBar, Header } from '../components'

import { Modals } from 'src/types/modals'

import './game.scss'
import './ui.scss'

const uiSettings = {
  buttonNextWidth: 64,
  paddingPage: 24,
}

const getInitialState = () => ({
  playerState: {
    HasClicked: false,
    HasStarted: false,
    StartPosition: {
      x: 0,
    },
    MovePosition: {
      x: 0,
    },
    LastPosition: {
      x: 0,
    },
    CurrentLeft: {
      x: 0,
    },
  },
  gameState: {
    currentScore: 0,
    maxScore: 10,
    fps: 60,
    speed: 15,
    delaySpawn: 1000,
    lastSpawn: 500,
    startTime: Date.now(),
    lastTick: Date.now(),
  },
  enemyInSceneList: [],
  welcomeModalVisible: false,
})

type Enemy = HTMLElement & {
  spawned?: boolean
  opacity?: boolean
}

type MedicineGameComponentProps = {
  closeGame: VoidFunction
}

type MedicineGameComponentState = {
  playerState: PlayerState
  gameState: GameState
  enemyInSceneList: Enemy[]
  finished: boolean
  welcomeModalVisible: boolean
}

class MedicineGameComponent extends React.Component<
  MedicineGameComponentProps,
  MedicineGameComponentState
> {
  playerBlock = null
  playerCart = null
  gameScene = null
  enemyBlocks = null
  interval: NodeJS.Timer | null = null
  playerCartRef: React.RefObject<HTMLDivElement>
  gameSceneRef: React.RefObject<HTMLDivElement>
  enemyBlocksRef: React.RefObject<HTMLDivElement>
  playerBlockRef: React.RefObject<HTMLDivElement>

  constructor(props: MedicineGameComponentProps) {
    super(props)

    this.state = getInitialState()

    this.playerCartRef = React.createRef()
    this.gameSceneRef = React.createRef()
    this.enemyBlocksRef = React.createRef()
    this.playerBlockRef = React.createRef()
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.OnPlayerDown, false)
    document.addEventListener('mouseup', this.OnPlayerUp, false)
    document.addEventListener('mousemove', this.OnPlayerMove, false)

    document.addEventListener('touchstart', this.OnPlayerTouchStart, false)
    document.addEventListener('touchend', this.OnPlayerTouchEnd, false)
    document.addEventListener('touchmove', this.OnPlayerTouchMove, false)

    this.showWelcomeModal()
  }

  componentWillUnmount = () => {
    if (this.interval) {
      clearInterval(this.interval)
    }

    document.removeEventListener('mousedown', this.OnPlayerDown, false)
    document.removeEventListener('mouseup', this.OnPlayerUp, false)
    document.removeEventListener('mousemove', this.OnPlayerMove, false)

    document.removeEventListener('touchstart', this.OnPlayerTouchStart, false)
    document.removeEventListener('touchend', this.OnPlayerTouchEnd, false)
    document.removeEventListener('touchmove', this.OnPlayerTouchMove, false)
  }

  showWelcomeModal = () => {
    this.setState({
      ...this.state,
      welcomeModalVisible: true,
    })
  }

  closeWelcomeModal = () => {
    this.setState({
      ...this.state,
      welcomeModalVisible: false,
    })
    this.initGame()
  }

  OnPlayerDown = () => {
    this.setState({
      ...this.state,
      playerState: {
        ...this.state.playerState,
        HasClicked: true,
      },
    })
  }

  OnPlayerUp = () => {
    this.setState({
      ...this.state,
      playerState: {
        ...this.state.playerState,
        HasClicked: false,
        HasStarted: false,
      },
    })
  }

  OnPlayerMove = (env) => {
    const clientX = env.clientX
    this.SetPlayerPositionX(clientX)
  }

  OnPlayerTouchStart = (env) => {
    this.setState({
      ...this.state,
      playerState: {
        ...this.state.playerState,
        HasClicked: true,
      },
    })
  }

  OnPlayerTouchEnd = (env) => {
    this.setState({
      ...this.state,
      playerState: {
        ...this.state.playerState,
        HasClicked: false,
        HasStarted: false,
      },
    })
  }

  OnPlayerTouchMove = (env) => {
    const clientX = env.touches[0].clientX
    this.SetPlayerPositionX(clientX)
  }

  SetPlayerPositionX = (clientX) => {
    const { playerState } = this.state

    if (playerState.HasClicked == false) {
      return
    }

    if (clientX < 0 || clientX > this.gameSceneRef.current!.clientWidth) {
      return
    }

    const NewPlayerState = {
      ...this.state.playerState,
    }

    if (playerState.HasStarted == false) {
      playerState.HasStarted = true
      playerState.StartPosition.x = clientX
      playerState.LastPosition.x = clientX
      playerState.MovePosition.x = this.playerBlockRef.current!.offsetLeft
    } else {
      if (
        clientX - playerState.LastPosition.x > 0 &&
        playerState.ToLeft == true
      ) {
        NewPlayerState.ToLeft = false
        NewPlayerState.HasStarted = false
      } else if (
        clientX - playerState.LastPosition.x < 0 &&
        playerState.ToLeft == false
      ) {
        NewPlayerState.ToLeft = true
        NewPlayerState.HasStarted = false
      }

      NewPlayerState.LastPosition.x = clientX

      let newLeft =
        playerState.MovePosition.x + (clientX - playerState.StartPosition.x)

      if (newLeft < uiSettings.buttonNextWidth) {
        newLeft = uiSettings.buttonNextWidth
      } else if (
        newLeft >
        this.gameSceneRef.current!.clientWidth -
          uiSettings.buttonNextWidth -
          this.playerBlockRef.current!.clientWidth
      ) {
        newLeft =
          this.gameSceneRef.current!.clientWidth -
          uiSettings.buttonNextWidth -
          this.playerBlockRef.current!.clientWidth
      }

      this.playerBlockRef.current!.style.left = newLeft + 'px'

      this.setState({
        ...this.state,
        playerState: NewPlayerState,
      })
    }
  }

  initGame = (): void => {
    this.interval = setInterval(() => {
      try {
        this.OnGameTick(Date.now() - this.state.gameState.lastTick)
      } catch (e) {
        console.error(e)
      }
      this.setState({
        ...this.state,
        gameState: {
          ...this.state.gameState,
          lastTick: Date.now(),
        },
      })
    }, 1000 / this.state.gameState.fps)
  }

  Restart = () => {
    this.setState(getInitialState())
    this.initGame()
  }

  OnGameTick = (deltaTime: number): void => {
    if (this.CanNewSpawnEnemy()) {
      this.SpawnEnemy()
    }

    const playerRect = this.playerCartRef.current!.getBoundingClientRect()

    for (const enemy of this.state.enemyInSceneList) {
      this.OnEnemyUpdate(deltaTime, enemy, playerRect)
    }
  }

  CanNewSpawnEnemy = () => {
    return (
      Date.now() - this.state.gameState.lastSpawn >
      this.state.gameState.delaySpawn
    )
  }

  SpawnEnemy = () => {
    const newEnemy: Enemy = document.createElement('div')
    const salt =
      Date.now() +
      this.state.enemyInSceneList.length +
      this.state.gameState.currentScore +
      this.state.playerState.CurrentLeft.x
    let leftPosition =
      Date.now() %
      (this.gameSceneRef.current!.clientWidth -
        uiSettings.buttonNextWidth -
        uiSettings.paddingPage)
    if (leftPosition < uiSettings.buttonNextWidth) {
      leftPosition = uiSettings.buttonNextWidth
    }

    newEnemy.classList.add('enemy-block')
    newEnemy.classList.add('enemy-' + ((salt % 7) + 1))
    newEnemy.style.top = '0px'
    newEnemy.style.left = leftPosition + 'px'
    newEnemy.spawned = true
    newEnemy.opacity = true

    this.enemyBlocksRef.current!.appendChild(newEnemy)
    this.setState({
      ...this.state,
      gameState: {
        ...this.state.gameState,
        lastSpawn: Date.now(),
      },
      enemyInSceneList: [...this.state.enemyInSceneList, newEnemy],
    })
  }

  OnEnemyUpdate = (deltaTime: number, enemy: any, playerRect: any) => {
    const top = Number(enemy.style.top.substr(0, enemy.style.top.length - 2))
    const newTop = top + deltaTime * (this.state.gameState.speed / 100)

    if (newTop > this.gameSceneRef.current!.clientHeight) {
      this.RemoveEnemy(enemy)
      return
    }

    if (enemy.spawned == true) {
      enemy.spawned = false
    } else if (enemy.opacity == true) {
      enemy.opacity = false
      enemy.style.opacity = 1
    }

    if (this.CheckCollision(enemy, playerRect) === true) {
      this.UpScore()
      return
    }

    enemy.style.top = newTop + 'px'
  }

  RemoveEnemy = (enemy: any) => {
    const index = this.state.enemyInSceneList.indexOf(enemy)
    const newEnemyInSceneList = this.state.enemyInSceneList.slice()
    newEnemyInSceneList.splice(index, 1)
    this.setState({
      ...this.state,
      enemyInSceneList: newEnemyInSceneList,
    })
    enemy.remove()
  }

  CheckCollision = (enemy: Enemy, playerRect: any): boolean => {
    if (
      enemy.offsetTop + enemy.clientHeight + 10 >=
        this.playerBlockRef.current!.offsetTop &&
      enemy.offsetTop + enemy.clientHeight <
        this.playerBlockRef.current!.offsetTop + enemy.clientHeight
    ) {
      const enemyRect = enemy.getBoundingClientRect()
      if (
        this.HasCollision(playerRect, enemyRect) &&
        playerRect.y + 5 > enemyRect.y + enemyRect.height
      ) {
        this.RemoveEnemy(enemy)
        return true
      }
    }
    return false
  }

  HasCollision = (rect1: any, rect2: any): boolean => {
    if (
      rect1.x < rect2.x + rect2.width &&
      rect1.x + rect1.width > rect2.x &&
      rect1.y < rect2.y + rect2.height &&
      rect1.y + rect1.height > rect2.y
    ) {
      return true
    }

    return false
  }

  UpScore = () => {
    this.setState({
      ...this.state,
      gameState: {
        ...this.state.gameState,
        currentScore: this.state.gameState.currentScore + 1,
      },
    })

    if (this.state.gameState.currentScore >= this.state.gameState.maxScore) {
      this.GameFinish()
    }
  }

  GameFinish = () => {
    clearInterval(this.interval!)

    this.state.enemyInSceneList.forEach((item) => this.RemoveEnemy(item))

    this.setState({
      ...this.state,
      finished: true,
    })
  }

  openAdvantageModal = () => {
    this.props.openModal(Modals.pharmacy)
    this.GameClose()
  }

  GameClose = () => {
    this.props.closeGame()
  }

  render() {
    const barWidth =
      (this.state.gameState.currentScore * 100) /
        this.state.gameState.maxScore +
      '%'

    return (
      <div id="game-blog-medicine">
        <Header onClose={this.GameClose}>
          <ProgressBar barWidth={barWidth} />
        </Header>
        <div className="game-wrapper">
          <div className="game-scene" ref={this.gameSceneRef}>
            <div className="enemy-blocks" ref={this.enemyBlocksRef}></div>
            <div className="player-block" ref={this.playerBlockRef}>
              <div className="player-cart" ref={this.playerCartRef}></div>
            </div>
          </div>
        </div>
        <div className="score-line">
          <span className="current">{this.state.gameState.currentScore}</span> /{' '}
          <span className="max">{this.state.gameState.maxScore}</span>
        </div>
        <EndGameModal
          visible={this.state.finished}
          score={this.state.gameState.currentScore}
          maxScore={this.state.gameState.maxScore}
          restart={this.Restart}
          next={this.openAdvantageModal}
        />
        <WelcomeModal
          visible={this.state.welcomeModalVisible}
          onClose={this.closeWelcomeModal}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (name: Modals) => dispatch(appActions.appModalOpen(name)),
  }
}

export const MedicineGame = connect(
  null,
  mapDispatchToProps,
)(MedicineGameComponent)
