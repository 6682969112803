import React from 'react'
import { Modal } from 'src/components'
import { Styled } from './styles'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'

type PdfViewerProps = {
  src: string
  visible: boolean
  onClose: VoidFunction
}

export const PdfViewer: React.FC<PdfViewerProps> = (props) => {
  const { src } = props

  return (
    <Modal {...props} centered={true} footer={null}>
      <Styled.Wrapper>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer fileUrl={src} />
        </Worker>
      </Styled.Wrapper>
    </Modal>
  )
}
