export enum Modals {
  okko = 'okko',
  citymobil = 'citymobil',
  pharmacy = 'pharmacy',
  megamarket = 'megamarket',
  market = 'market',
  sound = 'sound',
  scooter = 'scooter',
  disk = 'disk',
  health = 'health',
  mobile = 'mobile',
  deliveryСlub = 'deliveryСlub',

  prime = 'prime',
  primePdf = 'primePdf',

  consultant = 'consultant',
  minusChannel = 'minusChannel',
  music = 'music',
  visit = 'visit',

  welcome = 'welcome',

  welcomeKitchen = 'welcomeKitchen',
  welcomeBathroom = 'welcomeBathroom',
  welcomeLivingRoom = 'welcomeLivingRoom',
  welcomeBedroom = 'welcomeBedroom',

  sberPortal = 'sberPortal',
}
