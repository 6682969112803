import { Hero, Modals } from 'src/types'

import { Scenario } from 'src/redux/ducks/map'

export enum GameActions {
  foodGame = 'foodGame',
  taxiGame = 'taxiGame',
  medicineGame = 'medicineGame',
}

export type Actions = Scenario | GameActions

export interface AppInitialState {
  isLoaderVisible: boolean
  hero: Hero | null
  modal: {
    visible: boolean
    name: Modals | null
  }
  benefits: {
    [key: string]: boolean
  }
  lastAction: Actions | null
}
