import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { appActions, heroSelector } from 'src/redux/ducks/app'
import { Button, Title, Text } from 'src/components'
import { Styled } from './styles'
import home from 'src/shared/img/home.svg'
import benefits from 'src/shared/img/benefits.svg'

export const Welcome: React.FC<any> = (props) => {
  const dispatch = useDispatch()
  const hero = useSelector((state) => heroSelector(state))

  const closeModal = useCallback(() => {
    dispatch(appActions.appModalClose())
  }, [])

  return (
    <Styled.Wrapper hero={hero}>
      <Styled.Content>
        <Styled.Title>
          <Title type="h1">Приветствую в моем городе!</Title>
        </Styled.Title>
        <Text type="body2">
          В городе есть множество сервисов СБЕРа, с которыми ты можешь
          ознакомиться, нажав на элементы карты.
        </Text>
        <Styled.TextWithIcon>
          <Text type="body2">
            Ты можешь отправиться ко мне, нажав на кнопку{' '}
            <Text type="body1">«Домой»</Text>
          </Text>
          <img src={home} />
        </Styled.TextWithIcon>
        <Styled.TextWithIcon>
          <Text type="body2">
            Все твои достижения будут собраны в разделе{' '}
            <Text type="body1">«Подарки»</Text>
          </Text>
          <img src={benefits} />
        </Styled.TextWithIcon>
        <Styled.Button>
          <Button onClick={closeModal}>Начать игру</Button>
        </Styled.Button>
      </Styled.Content>
    </Styled.Wrapper>
  )
}
