import React from 'react'
import { Title, Text, Button } from 'src/components'
import { Styled } from './styles'
import { DISCLAIMER_TEXT } from './mock'

type DisclaimerProps = {
  goNext: VoidFunction
}

export const Disclaimer: React.FC<DisclaimerProps> = (props) => {
  const { goNext } = props

  return (
    <Styled.Wrapper>
      <div className="container">
        <Styled.List>
          {DISCLAIMER_TEXT.map((item) => (
            <Styled.Item key={item.title}>
              <Styled.Title>
                <Title type="h3">{item.title}</Title>
              </Styled.Title>

              <Styled.Text>
                {item.ogrn && <Text type="body3">{item.ogrn}</Text>}
                <Text type="body3">{item.text}</Text>
              </Styled.Text>
              <Text type="body3">
                <b>{item.link}</b>
              </Text>
            </Styled.Item>
          ))}
        </Styled.List>

        <Button onClick={goNext}>Далее</Button>
      </div>
    </Styled.Wrapper>
  )
}
