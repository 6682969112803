import React from 'react'
import { useSelector } from 'react-redux'
import { heroSelector } from 'src/redux/ducks/app'
import { Styled } from './styles'
import { Modal, Button, Title, Text } from 'src/components'
import img1 from 'src/shared/img/food/cheese.svg'
import img2 from 'src/shared/img/food/green-canned.svg'
import img3 from 'src/shared/img/food/tomato.svg'

type Props = {
  onClose: VoidFunction
  visible: boolean
}

export const WelcomeModal: React.FC<Props> = (props) => {
  const { onClose } = props
  const hero = useSelector((state) => heroSelector(state))

  return (
    <Modal
      {...props}
      hideCloseButton={true}
      centered={true}
      footer={null}
      className={'modal-welcome'}
    >
      <Styled.Wrapper hero={hero}>
        <Styled.Content>
          <Styled.Title>
            <Title type="h1">Закажем продукты?</Title>
          </Styled.Title>
          <Text type="body2">
            Заказать продукты в <Text type="body1">«Самокат»</Text> очень
            просто. Это так же просто, как сыграть в игру.
          </Text>

          <Styled.TextWithIcon>
            <Text type="body2">Нажимай на продукты </Text>
            <Styled.ImageList>
              <img src={img1} />
              <img src={img2} />
              <img src={img3} />
            </Styled.ImageList>
            <Text type="body2">и получай очки.</Text>
          </Styled.TextWithIcon>

          <Text type="body2">
            После завершения игры я расскажу тебе о преимуществах сервиса{' '}
            <Text type="body1">«Самокат».</Text>{' '}
          </Text>
          <Styled.Button>
            <Button onClick={onClose}>Начать игру</Button>
          </Styled.Button>
        </Styled.Content>
      </Styled.Wrapper>
    </Modal>
  )
}
