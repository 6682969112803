import { createSlice } from '@reduxjs/toolkit'
import { AppInitialState } from './'
import { Hero } from 'src/types'

const initialState: AppInitialState = {
  isLoaderVisible: false,
  hero: null,
  modal: {
    visible: false,
    name: null,
  },
  benefits: {},
  lastAction: null,
}

const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showLoader: (state) => ({
      ...state,
      isLoaderVisible: true,
    }),
    hideLoader: (state) => ({
      ...state,
      isLoaderVisible: false,
    }),
    setHero: (state, action) => ({
      ...state,
      hero: action.payload,
    }),
    appModalOpen: (state, action) => ({
      ...state,
      modal: {
        visible: true,
        name: action.payload,
      },
    }),
    appModalClose: (state) => ({
      ...state,
      modal: {
        visible: false,
        name: null,
      },
    }),

    setBenefit: (state, action) => ({
      ...state,
      benefits: {
        ...state.benefits,
        [action.payload]: true,
      },
    }),

    setLastAction: (state, action) => ({
      ...state,
      lastAction: action.payload,
    }),
  },
  extraReducers: {},
})

export const appReducer = AppSlice.reducer
export const appActions = AppSlice.actions
