import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from 'rdx/index'
import { AppRouter } from 'src/AppRouter'
import { AppStyled } from './styles'
import { AppModal, Text } from 'src/components'

const AppComponent = () => {
  return <AppRouter />
}

import { useState, useEffect } from 'react'

const Orientation = () => {
  const style = {
    zIndex: 99999,
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0 10px',
    textAlign: 'center',
    background: '#334047',
  }

  const styleInner = {
    maxWidth: 300,
  }

  const isLandscape = () =>
      window.matchMedia('(orientation:landscape)').matches,
    [orientation, setOrientation] = useState(
      isLandscape() ? 'landscape' : 'portrait',
    ),
    onWindowResize = () => {
      clearTimeout(window.resizeLag)
      window.resizeLag = setTimeout(() => {
        delete window.resizeLag
        setOrientation(isLandscape() ? 'landscape' : 'portrait')
      }, 200)
    }

  useEffect(
    () => (
      onWindowResize(),
      window.addEventListener('resize', onWindowResize),
      () => window.removeEventListener('resize', onWindowResize)
    ),
    [],
  )

  console.log('isLandscape', isLandscape)

  if (orientation === 'portrait') {
    return (
      <div style={style}>
        <div style={styleInner}>
          <Text type="body1">
            Для работы с приложением, переверните экран в горизонтальное
            положение
          </Text>
        </div>
      </div>
    )
  }

  return null
}

export function App() {
  return (
    <AppStyled>
      <ReduxProvider store={store}>
        <AppComponent />
        <AppModal />
        <Orientation />
      </ReduxProvider>
    </AppStyled>
  )
}
