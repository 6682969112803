import styled from 'styled-components'
import { CardStyle, CardStyleActive } from 'styles/card'
import { colors } from 'src/styles/colors'

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  ${CardStyle}
  ${CardStyleActive}

  svg {
    fill: ${colors.HERBAL};
    transition: all 0.2s;
  }

  &:hover,
  &:active,
  &:focus {
    svg {
      fill: ${colors.GREEN};
    }
  }
`

export const Styled = {
  Button,
}
