import React from 'react'
import { useSelector } from 'react-redux'
import { heroSelector } from 'src/redux/ducks/app'
import { Styled } from './styles'
import { Modal, Button, Title, Text } from 'src/components'
import img from '../../images/game-player-1.svg'

type Props = {
  onClose: VoidFunction
  visible: boolean
}

export const WelcomeModal: React.FC<Props> = (props) => {
  const { onClose } = props
  const hero = useSelector((state) => heroSelector(state))

  return (
    <Modal
      {...props}
      hideCloseButton={true}
      centered={true}
      footer={null}
      className={'modal-welcome'}
    >
      <Styled.Wrapper hero={hero}>
        <Styled.Content>
          <Styled.Title>
            <Title type="h1">Закажем лекарства?</Title>
          </Styled.Title>
          <Text type="body2">
            Заказать продукты в <Text type="body1">«ЕАптека»</Text> очень легко.
            Это так же легко, как сыграть в игру.
          </Text>

          <Styled.TextWithIcon>
            <Text type="body2">Перемещай коробку </Text>
            <img src={img} />
            <Text type="body2">чтобы ловить лекарства и получай очки.</Text>
          </Styled.TextWithIcon>

          <Text type="body2">
            После завершения игры я расскажу тебе о преимуществах сервиса{' '}
            <Text type="body1">«ЕАптека».</Text>{' '}
          </Text>
          <Styled.Button>
            <Button onClick={onClose}>Начать игру</Button>
          </Styled.Button>
        </Styled.Content>
      </Styled.Wrapper>
    </Modal>
  )
}
