import * as THREE from 'three'

type Group = {
  name: string
  elementNames: string[]
}

type AnimateGroups = Group[]

interface ClipManagerInterface {
  splitClipsByNames(params: {
    groups: AnimateGroups
    animationClip: THREE.AnimationClip
  }): THREE.AnimationClip[] | null
}

export class ClipManager implements ClipManagerInterface {
  // make clips from one of the biggest clip
  splitClipsByNames = (params: {
    groups: AnimateGroups
    animationClip: THREE.AnimationClip
  }): THREE.AnimationClip[] => {
    const { groups, animationClip } = params

    const animationClips: THREE.AnimationClip[] = []

    groups.forEach((group) => {
      const tracks: THREE.KeyframeTrack[] = animationClip.tracks.filter(
        (track) => {
          const dot = track.name.lastIndexOf('.')
          const name = track.name.substring(0, dot)

          return group.elementNames.includes(name)
        },
      )

      if (!tracks.length) {
        return
      }

      animationClips.push({
        ...animationClip,
        name: group.name,
        tracks,
        uuid: group.name,
      })
    })

    return animationClips
  }

  getObjectNamesMapByClip = (clip: THREE.AnimationClip) => {
    const objectNames: { [key: string]: boolean } = {}

    clip.tracks.forEach((track) => {
      const dot = track.name.lastIndexOf('.')
      const name = track.name.substring(0, dot)

      objectNames[name] = true
    })

    return objectNames
  }
}

export class MapClipManager extends ClipManager {
  // ищет все анимируемые объекты исключая те что в группах
  getGroupExcludingNamesFromGroups = (params: {
    groups: AnimateGroups
    animationClip: THREE.AnimationClip
  }): Group => {
    const { groups, animationClip } = params

    const allNamesFromGroups: string[] = groups.reduce(
      (accomulator: string[], current) => {
        return [...accomulator, ...current.elementNames]
      },
      [],
    )

    const names: {
      [key: string]: string
    } = {}

    animationClip.tracks.forEach((track) => {
      const dot = track.name.lastIndexOf('.')
      const name = track.name.substring(0, dot)

      if (!allNamesFromGroups.includes(name)) {
        names[name] = name
      }
    })

    const mainGroup = {
      name: 'main',
      elementNames: Object.values(names),
    }

    return mainGroup
  }
}
