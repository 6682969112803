export enum Scenario {
  goHomeOnFoot = 'goHomeOnFoot',
  goHomeByCar = 'goHomeByCar',
  goHomeByTaxi = 'goHomeByTaxi',
  goHomeByBus = 'goHomeByBus',
  goMarket = 'goMarket',
  goPharmacy = 'goPharmacy',
}

export interface MapInitialState {
  scenario: Scenario | null
}
