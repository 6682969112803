import React from 'react'
import { Text } from 'src/components'
import { Styled } from './styles'
import { useSelector } from 'react-redux'
import { appBenefitsSelector } from 'src/redux/ducks/app'
import check from 'src/shared/img/check.svg'

type PrimeItem = {
  logo: any
  title: string
  subtitle: string
}

type Column = PrimeItem[]

type PrimeProps = {
  data: Column[]
}

export const Prime: React.FC<PrimeProps> = (props) => {
  const benefits = useSelector((state) => appBenefitsSelector(state))
  const { data } = props

  return (
    <Styled.Wrapper>
      {data.map((column, i) => {
        return (
          <Styled.Column key={i}>
            {column.map((item) => (
              <Styled.Item key={item.title} checked={benefits[item.key]}>
                <Styled.Logo>
                  <img src={item.logo} />
                </Styled.Logo>
                <Styled.ItemContent>
                  <Styled.Title>
                    <Text type="body1">{item.title}</Text>
                    {benefits[item.key] && <img src={check} alt="" />}
                  </Styled.Title>
                  <Text type="body3">{item.subtitle}</Text>
                </Styled.ItemContent>
              </Styled.Item>
            ))}
          </Styled.Column>
        )
      })}
    </Styled.Wrapper>
  )
}
