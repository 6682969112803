import styled from 'styled-components'
import { css } from 'styled-components'
import { colors } from 'src/styles/colors'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${colors.GRADIENT_BLUE_LIGHT};

  @media (max-height: 550px) {
    align-items: flex-start;
    padding-top: 55px;
  }

  @media (max-height: 413px) {
    align-items: flex-start;
    padding-top: 5vh;
  }
`

const BackButton = styled.div`
  position: absolute;
  top: 8px;
  right: 24px;
`

const Fridge = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-height: 413px) {
    // height: calc(100vh - 55px);
    max-height: calc(95vh);
  }
`

// fridge

const Content = styled.div`
  position: relative;
  margin-right: 26px;

  @media (max-height: 413px) {
    // height: calc(100vh - 55px);
    // max-height: calc(95vh);
    max-height: calc(100% + 120px);
  }
`

const ImageItemStyle = css`
  position: absolute;
  opacity: 0;
`

const FridgeImage = styled.img`
  width: 391px;

  @media (max-height: 413px) {
    width: auto;
    max-height: calc(100% + 120px);
  }
`

const TomatoFirst = styled.img`
  top: 27.7%;
  left: 7.2%;
  width: 8.7%;
  ${ImageItemStyle}
`

const TomatoSecond = styled.img`
  top: 29.7%;
  left: 15.1%;
  width: 6.8%;
  ${ImageItemStyle}
`

const Bread = styled.img`
  top: 55.2%;
  left: 4.5%;
  transform: rotate(23deg);
  width: 20%;
  ${ImageItemStyle}
`

const Cheese = styled.img`
  width: 10.5%;
  top: 15%;
  left: 11%;
  ${ImageItemStyle}
`

const Zucchini = styled.img`
  top: 13%;
  left: 37.2%;
  width: 14.5%;
  transform: rotate(41deg);
  ${ImageItemStyle}
`

// recipe

const Recipe = styled.div`
  position: relative;
  max-height: 100%;
  top: 8px;

  @media (max-height: 413px) {
    // height: calc(100vh - 55px);
    max-height: calc(95vh);
  }
`

const RecipeImageItemStyle = css`
  position: absolute;
  opacity: 0.5;
`

const ItemRecipe = styled.div`
  img {
    opacity: ${(props) => {
      if (props.filled === 'filled') {
        return 1
      }

      if (props.filled === 'filledPart') {
        return 0.8
      }

      return 0.5
    }};
  }
`

const TomatoRecipe = styled.img`
  top: 47.7%;
  left: 26.2%;
  width: 15.7%;
  ${RecipeImageItemStyle}
`

const BreadRecipe = styled.img`
  top: 82.5%;
  left: 23.5%;
  transform: rotate(-20deg);
  width: 22%;
  ${RecipeImageItemStyle}
`

const CheeseRecipe = styled.img`
  top: 35.1%;
  left: 25%;
  width: 17.5%;
  ${RecipeImageItemStyle}
`

const ZucchiniRecipe = styled.img`
  top: 63.7%;
  left: 24.2%;
  width: 18.9%;
  transform: rotate(12deg);
  ${RecipeImageItemStyle}
`

export const Styled = {
  Wrapper,
  BackButton,
  Fridge,
  Content,
  Recipe,
  // fridge
  FridgeImage,
  TomatoFirst,
  TomatoSecond,
  Bread,
  Cheese,
  Zucchini,
  // recipe
  ItemRecipe,
  TomatoRecipe,
  BreadRecipe,
  CheeseRecipe,
  ZucchiniRecipe,
}
