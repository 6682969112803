import React from 'react'
import { useDispatch } from 'react-redux'
import { appActions } from 'src/redux/ducks/app'
import { Room } from '../'
import { Models } from '../../types'
import { Modals } from 'src/types/modals'
import history from 'src/history'

import { RouteWithSubRoutes } from 'src/components'
import { RouteType } from 'types/router'

type BedroomProps = {
  routes: RouteType[]
}

export const Bedroom: React.FC<BedroomProps> = ({ routes }) => {
  const dispatch = useDispatch()

  const objectClickHandler = (mesh) => {
    console.log('mesh', mesh.name)

    switch (mesh.name) {
      case 'Bedroom_Character': {
        dispatch(appActions.appModalOpen(Modals.music))
        return
      }
      case 'SberPortal': {
        history.push('/city/home/bedroom/sber-portal')
      }
    }
  }

  return (
    <>
      <Room model={Models.bedroom} handleObjectClick={objectClickHandler} />

      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </>
  )
}
