import React from 'react'
import benefits from 'src/shared/img/benefits.svg'
import { Styled } from './styles'
import { appBenefitsCountSelector } from 'src/redux/ducks/app'
import { useSelector } from 'react-redux'

export const Benefits: React.FC<{}> = () => {
  const benefitsCount = useSelector((state) => appBenefitsCountSelector(state))

  return (
    <Styled.Wrapper>
      <img src={benefits} alt="" />
      {benefitsCount > 0 && (
        <Styled.Badge small={benefitsCount > 9}>{benefitsCount}</Styled.Badge>
      )}
    </Styled.Wrapper>
  )
}
