export enum ClipNames {
  Main = 'main',

  womenVisitByCar = 'womenVisitByCar',
  manVisitByCar = 'manVisitByCar',

  menGoMarket = 'menGoMarket',
  womenGoMarket = 'womenGoMarket',

  manGoPharmacy = 'manGoPharmacy',
  womenGoPharmacy = 'womenGoPharmacy',

  manGoHomeByBus = 'manGoHomeByBus',
  womenGoHomeByBus = 'womenGoHomeByBus',

  manGoHomeByTaxi = 'manGoHomeByTaxi',
  womenGoHomeByTaxi = 'womenGoHomeByTaxi',
}
