import styled from 'styled-components'
import { colors } from 'styles/colors'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  canvas {
    width: 100% !important;
    height: 100% !important;
    cursor: pointer;
  }
`

const Loader = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${colors.SNOW};
`

export const Styled = {
  Wrapper,
  Loader,
}
