import React from 'react'
import { Styled } from './styles'
import { Justify } from 'src/styles/types'

type CardListProps = {
  value?: any
  onChange?: (value: any) => void
  justify?: Justify
}

export const CardList: React.FC<CardListProps> = (props) => {
  const { onChange, value, justify } = props

  if (onChange) {
    const childrenWithProps = React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          onClick: () => onChange(child.props.value),
          selected: child.props.value === value,
        })
      }

      return child
    })

    return (
      <Styled.CardList justify={justify}>{childrenWithProps}</Styled.CardList>
    )
  }

  return <Styled.CardList justify={justify}>{props.children}</Styled.CardList>
}
