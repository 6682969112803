import React from 'react'
import { Styled } from '../styles'
import { Button, Title, Text } from 'src/components'

type Props = {
  closeModal: VoidFunction
}

export const WelcomeBedroom: React.FC<Props> = (props) => {
  const { closeModal } = props

  return (
    <>
      <Styled.Title>
        <Title type="h1">Приветствую! Поможешь мне?</Title>
      </Styled.Title>
      <Text type="body2">
        Мы находимся у меня в спальне и можем послушать музыку.
      </Text>
      <Text type="body2">
        Для начала нужно нажать на <Text type="body1">телефон</Text> в моей
        руке.
      </Text>
      <Text type="body2">
        Далее выбери <Text type="body1">«расширить список композиций»,</Text> и
        я расскажу тебе про преимущества сервиса{' '}
        <Text type="body1">«СберЗвук».</Text>
      </Text>
      <Styled.Button>
        <Button onClick={closeModal}>Начать игру</Button>
      </Styled.Button>
    </>
  )
}
