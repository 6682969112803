import React from 'react'
import { Styled } from '../styles'
import { Button, Title, Text } from 'src/components'

type Props = {
  closeModal: VoidFunction
}

export const WelcomeKitchen: React.FC<Props> = (props) => {
  const { closeModal } = props

  return (
    <>
      <Styled.Title>
        <Title type="h1">Приветствую! Поможешь мне?</Title>
      </Styled.Title>
      <Text type="body2">Мы находимся у меня на кухне.</Text>
      <Text type="body2">Мне нужно приготовить еду по рецепту.</Text>
      <Text type="body2">
        Для начала нужно нажать на <Text type="body1">холодильник</Text> и
        выбрать те ингредиенты, которые указаны в рецепте. Справишься?
      </Text>
      <Styled.Button>
        <Button onClick={closeModal}>Начать игру</Button>
      </Styled.Button>
    </>
  )
}
