import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mapActions, Scenario } from 'src/redux/ducks/map'

import { useHistory } from 'react-router-dom'
import { appActions, heroSelector } from 'src/redux/ducks/app'

import { Modal } from 'src/components'
import { Title, Text, Card } from 'src/components'
import { ModalProps } from 'antd'
import man from 'src/shared/img/man-with-bag.svg'
import women from 'src/shared/img/women-with-bag.svg'
import courier from 'src/shared/img/courier-with-food.svg'

import { Hero } from 'src/types'

import { Modals } from 'src/types/modals'

type FridgeModalProps = ModalProps & {
  visible: boolean
  onClose: VoidFunction
}

export const FridgeModal: React.FC<FridgeModalProps> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const hero = useSelector((state) => heroSelector(state))

  const { onClose } = props

  const handleClose = () => {
    onClose()
    dispatch(appActions.appModalOpen(Modals.scooter))
    dispatch(appActions.setLastAction(null))
  }

  const goToMarket = () => {
    onClose()
    history.push('/city')
    dispatch(appActions.setLastAction(Scenario.goMarket))
    dispatch(mapActions.activateScenario(Scenario.goMarket))
  }

  const modalClose = () => {
    onClose()
    dispatch(appActions.setLastAction(null))
  }

  return (
    <Modal {...props} centered={true} footer={null} onClose={modalClose}>
      <Title type="h1">Ингредиенты кончаются!</Title>
      <Text type="body2">Нужно купить новые. Какое действие выберете?</Text>
      <Card.List>
        <Card.Item onClick={goToMarket}>
          <Card.ItemImage>
            <img src={hero === Hero.men ? man : women} alt="" />
          </Card.ItemImage>
          <Text type="body1">Сходить в магазин</Text>
        </Card.Item>
        <Card.Item onClick={handleClose}>
          <Card.ItemImage>
            <img src={courier} alt="" />
          </Card.ItemImage>
          <Text type="body1">Заказать доставку</Text>
        </Card.Item>
      </Card.List>
    </Modal>
  )
}
