import React from 'react'
import { connect } from 'react-redux'
import { appActions } from 'src/redux/ducks/app'
import { Modals } from 'src/types/modals'
import cn from 'classnames'
import { EndGameModal, WelcomeModal } from './components'
import { Header } from '../components'
import './game.scss'

const getInitialState = () => ({
  gameState: {
    hasActiveGame: false,
    currentSecond: 0,
    activeIcon: null,
    GoodPos: [6, 11, 3, 8, 13, 9, 14, 5, 10, 15, 2, 1, 7, 12, 4],
    elementsState: [
      {
        iconId: 1,
        posId: 6,
      },
      {
        iconId: 2,
        posId: 11,
      },
      {
        iconId: 3,
        posId: 3,
      },
      {
        iconId: 4,
        posId: 8,
      },
      {
        iconId: 5,
        posId: 13,
      },
      {
        iconId: 6,
        posId: 9,
      },
      {
        iconId: 7,
        posId: 14,
      },
      {
        iconId: 8,
        posId: 5,
      },
      {
        iconId: 9,
        posId: 10,
      },
      {
        iconId: 10,
        posId: 15,
      },
      {
        iconId: 11,
        posId: 2,
      },
      {
        iconId: 12,
        posId: 1,
      },
      {
        iconId: 13,
        posId: 7,
      },
      {
        iconId: 14,
        posId: 12,
      },
      {
        iconId: 15,
        posId: 4,
      },
    ],
  },
  gameSettings: {
    intervalCheckFinish: 1000,
    maxSecond: 60000,
    fps: 60,
  },
  finished: false,
  welcomeModalVisible: false,
})

type TVGameComponentProps = {
  closeGame: VoidFunction
}

type Element = {
  iconId: number
  posId: number
  focused?: boolean
}

type TVGameComponentState = {
  gameState: {
    hasActiveGame: boolean
    currentSecond: number
    activeIcon: Element | null
    GoodPos: number[]
    elementsState: Element[]
  }
  gameSettings: {
    intervalCheckFinish: number
    maxSecond: number
    fps: number
  }
  finished: boolean
  welcomeModalVisible: boolean
}

class TVGameComponent extends React.Component<
  TVGameComponentProps,
  TVGameComponentState
> {
  interval: NodeJS.Timer | null = null

  constructor(props: TVGameComponentProps) {
    super(props)

    this.state = getInitialState()

    this.interval = null
  }

  componentDidMount = () => {
    this.showWelcomeModal()
  }

  componentWillUnmount = () => {
    clearInterval(this.interval!)
  }

  showWelcomeModal = () => {
    this.setState({
      ...this.state,
      welcomeModalVisible: true,
    })
  }

  closeWelcomeModal = () => {
    this.setState(
      {
        ...this.state,
        welcomeModalVisible: false,
      },
      () => {
        this.initGame()
      },
    )
  }

  startTimer = () => {
    const { gameSettings, gameState } = this.state

    const interval = 1000 / gameSettings.fps
    this.interval = setInterval(this.gameTick, interval)
  }

  gameTick = () => {
    const { gameState, gameSettings } = this.state

    const interval = 1000 / gameSettings.fps

    if (gameState.hasActiveGame == true) {
      this.setState({
        ...this.state,
        gameState: {
          ...gameState,
          currentSecond: gameState.currentSecond + interval,
        },
      })

      if (gameState.currentSecond >= gameSettings.maxSecond) {
        this.onGameFinish()
      }
    }
  }

  closeGame = () => {
    this.props.closeGame()
  }

  onGameFinish = () => {
    const { gameState } = this.state

    if (gameState.hasActiveGame == true) {
      this.setState({
        ...this.state,
        gameState: {
          ...gameState,
          hasActiveGame: false,
        },
        finished: true,
      })

      clearInterval(this.interval!)
    }
  }

  initGameScene = () => {
    const initedPos: number[] = []
    const newElementsState: Element[] = []

    this.state.gameState.elementsState.forEach((item) => {
      let pos = 0

      while (true) {
        pos = (Date.now() % 15) + 1

        if (initedPos.indexOf(pos) === -1) {
          break
        }
      }

      initedPos.push(pos)

      newElementsState.push({
        ...item,
        posId: pos,
      })
    })

    this.setState({
      ...this.state,
      gameState: {
        ...this.state.gameState,
        elementsState: newElementsState,
        hasActiveGame: true,
        currentSecond: 0,
      },
    })
  }

  handleIconClick = (item: Element) => {
    const { gameState, gameSettings } = this.state

    if (!gameState.hasActiveGame) {
      return
    }

    const newElementsState = [...gameState.elementsState]

    const nextItem = newElementsState.findIndex(
      (itemFromState) => itemFromState.posId === item.posId,
    )

    if (gameState.activeIcon !== null) {
      const lastItem = newElementsState.findIndex(
        (item) => item.posId === gameState.activeIcon!.posId,
      )

      const lastPos = gameState.activeIcon.posId
      const nextPos = item.posId

      newElementsState[lastItem].posId = nextPos
      newElementsState[lastItem].focused = false

      newElementsState[nextItem].posId = lastPos

      this.setState({
        ...this.state,
        gameState: {
          ...this.state.gameState,
          activeIcon: null,
          elementsState: newElementsState,
        },
      })

      setTimeout(() => {
        this.onCheckGameFinish()
      }, gameSettings.intervalCheckFinish)
    } else {
      newElementsState[nextItem].focused = true

      this.setState({
        ...this.state,
        gameState: {
          ...this.state.gameState,
          activeIcon: {
            ...item,
            focused: true,
          },
          elementsState: newElementsState,
        },
      })
    }
  }

  onCheckGameFinish = (noFinishGame = false) => {
    const { gameState } = this.state

    let result = true

    for (let index = 1; index - 1 < gameState.GoodPos.length; index++) {
      const iconID = gameState.GoodPos[index - 1]
      const item = gameState.elementsState.find(
        (item) => item.iconId === iconID,
      )

      if (item!.posId !== index) {
        result = false
        break
      }
    }

    if (result == true && noFinishGame == false) {
      this.onGameFinish()
    }

    return result
  }

  initGame = (): void => {
    this.startTimer()
    this.initGameScene()
  }

  restart = () => {
    const initialState = getInitialState()
    this.setState(initialState, () => {
      this.initGame()
    })
  }

  openAdvantageModal = () => {
    this.props.openModal(Modals.okko)
    this.closeGame()
  }

  getScoreForView = () => {
    return Math.floor(this.state.gameState.currentSecond / 1000)
  }

  render() {
    const barWidth =
      100 -
      (this.state.gameState.currentSecond * 100) /
        this.state.gameSettings.maxSecond +
      '%'

    return (
      <div id="TVGame">
        <div className="top-line">
          <Header onClose={this.closeGame}>
            <div className="progress-bar">
              <div className="progress" style={{ width: barWidth }}></div>
            </div>
          </Header>
        </div>
        <div className="container">
          <div className="game-wrapper">
            <div className="game-scene">
              {this.state.gameState.elementsState.map((item) => (
                <div
                  key={item.iconId}
                  className={cn('game-icon', { focus: item.focused })}
                  data-icon_id={item.iconId}
                  data-pos_id={item.posId}
                  onClick={() => this.handleIconClick(item)}
                >
                  <div></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <EndGameModal
          visible={this.state.finished}
          score={this.getScoreForView()}
          restart={this.restart}
          next={this.openAdvantageModal}
        />
        <WelcomeModal
          visible={this.state.welcomeModalVisible}
          onClose={this.closeWelcomeModal}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (name: Modals) => dispatch(appActions.appModalOpen(name)),
  }
}

export const TVGame = connect(null, mapDispatchToProps)(TVGameComponent)
