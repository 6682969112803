import styled from 'styled-components'
import { Hero } from 'src/types'
import menWelcome from 'src/shared/img/men-welcome.svg'
import womenWelcome from 'src/shared/img/women-welcome.svg'

export const Wrapper = styled.div`
  padding: 25px 16px 16px;
  background-image: url(${(props) =>
    props.hero === Hero.men ? menWelcome : womenWelcome});
  background-repeat: no-repeat;
  background-position: bottom left 20px;

  @media (max-width: 660px), (max-height: 410px) {
    padding: 15px;
    background-size: 30%;
  }

  p {
    p {
      display: inline;
      margin-bottom: 0;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin-left: auto;

  @media (max-width: 660px) {
    max-width: 400px;
  }

  p {
    @media (max-width: 660px), (max-height: 410px) {
      margin-bottom: 5px;
    }
  }
`

export const Title = styled.div`
  margin-bottom: 10px;

  @media (max-width: 660px), (max-height: 410px) {
    margin-bottom: 5px;
  }
`

export const TextWithIcon = styled.div`
  max-width: 300px;
  margin-bottom: 10px;

  @media (max-width: 660px), (max-height: 410px) {
    margin-bottom: 5px;
  }

  p {
    display: inline;
  }

  img {
    position: relative;
    top: -3px;
    width: 60px;
    margin-left: 4px;
    margin-right: 4px;
    vertical-align: middle;

    &:last-child {
      margin-right: 8px;
    }

    @media (max-width: 660px), (max-height: 410px) {
      // width: 30px;
    }
  }
`

export const Button = styled.div`
  padding-top: 10px;
`

export const Styled = {
  Wrapper,
  Content,
  Title,
  TextWithIcon,
  Button,
}
