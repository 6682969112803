import React from 'react'
import { Styled } from './styles'
import pdf from './prime.pdf'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'

export const PrimePdf: React.FC<any> = () => {
  return (
    <Styled.Wrapper>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        <Viewer fileUrl={pdf} />
      </Worker>
    </Styled.Wrapper>
  )
}
