import React from 'react'
import { Styled } from './styles'
import { Button, Title, Text, Logo } from 'src/components'
import left from './left.svg'
import right from './right.svg'

type WelcomeProps = {
  goNext: VoidFunction
}

export const Welcome: React.FC<WelcomeProps> = (props) => {
  const { goNext } = props

  return (
    <Styled.Wrapper>
      <div className="container">
        <Styled.Content>
          <Logo />
          <Title type="h1">Экскурсия в Экосистему СБЕР</Title>
          <Styled.Description>
            <Text type="body2">
              Проходите жизненные сценарии, пока ждёте обслуживания
            </Text>
          </Styled.Description>
          <Styled.Button>
            <Button onClick={goNext}>Далее</Button>
          </Styled.Button>
        </Styled.Content>
      </div>
      <Styled.ImageLeft src={left} alt="" />
      <Styled.ImageRight src={right} alt="" />
    </Styled.Wrapper>
  )
}
