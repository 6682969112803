export const ITEMS = [
  {
    title: 'My Heart In Your Hands',
    subtitle: 'Breakdown of sanity',
    duration: '5.25',
  },
  {
    title: 'Rosenrot',
    subtitle: 'Rammstein',
    duration: '3:14',
  },
  {
    title: 'The Great Unknown',
    subtitle: 'Enter Shikari',
    duration: '4:14',
  },
]
