import React from 'react'
import { STEPS } from '../../types'
import { Welcome, Disclaimer } from './components'

type PlayInfoProps = {
  goNext: VoidFunction
  currentStep: STEPS
}

export const PlayInfo: React.FC<PlayInfoProps> = (props) => {
  const { goNext, currentStep } = props

  if (currentStep === STEPS.welcome) {
    return <Welcome goNext={goNext} />
  }

  if (currentStep === STEPS.disclaimer) {
    return <Disclaimer goNext={goNext} />
  }

  return null
}
