import React, { createRef } from 'react'
import { ModalProps } from 'antd'
import { Spin } from 'antd'
import { Styled } from './styles'

import * as THREE from 'three'
import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader'

import modelBathroom from './SM_Bathroom.glb'
import modelBedroom from './SM_Bedroom.glb'
import modelKitchen from './SM_Kitchen.glb'
import modelLivingRoom from './SM_LivingRoom.glb'

import modelBathroomEffects from './SM_BathroomFX.glb'
import modelKitchenEffects from './SM_KitchenFX.glb'
import modelLivingRoomEffects from './SM_LivingRoomFX.glb'

import { colors } from 'styles/colors'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import { Models } from '../../types'

import men from './men.png'
import women from './women.png'

import { connect } from 'react-redux'
import { heroSelector } from 'src/redux/ducks/app'
import { AppState } from 'src/redux/'
import { Hero } from 'src/types/hero'

const MAP_MODELS = {
  [Models.bathroom]: modelBathroom,
  [Models.bedroom]: modelBedroom,
  [Models.kitchen]: modelKitchen,
  [Models.LivingRoom]: modelLivingRoom,
}

const MAP_MODELS_EFFECT = {
  [Models.bathroom]: modelBathroomEffects,
  // [Models.bedroom]: ,
  [Models.kitchen]: modelKitchenEffects,
  [Models.LivingRoom]: modelLivingRoomEffects,
}

type RoomProps = ModalProps & {
  model: Models
  handleObjectClick: (mesh) => void
}

const params = {
  clearColor: colors.SNOW,
  exposure: 1,
}

type Game = {
  scene: THREE.Scene
  renderer: THREE.WebGLRenderer
  camera: THREE.Camera

  width: number
  height: number
  aspect: number
}

type RoomComponentState = {
  modelLoaded: boolean
}

export class RoomComponent extends React.Component<
  RoomProps,
  RoomComponentState
> {
  game: Game
  frameId: number | null
  d: number
  raycaster: THREE.Raycaster
  moved: boolean
  mouse: THREE.Vector2
  lastMove: any
  model: THREE.Group | null
  modelEffect: THREE.Group | null

  modelManager: THREE.LoadingManager

  constructor(props: any) {
    super(props)

    this.game = {}

    this.frameId = null

    this.d = 5

    this.raycaster = new THREE.Raycaster()
    this.mouse = new THREE.Vector2()

    this.moved = false
    this.lastMove = null

    this.model = null
    this.modelEffect = null

    this.modelManager = new THREE.LoadingManager()

    this.state = {
      modelLoaded: false,
    }
  }

  private wrapper = createRef<HTMLDivElement>()

  componentDidMount() {
    const { model } = this.props

    this.init()

    this.addModel(MAP_MODELS[model])
    {
      MAP_MODELS_EFFECT[model] && this.addModelEffects(MAP_MODELS_EFFECT[model])
    }

    this.start()
  }

  componentWillUnmount() {
    this.stop()
    this.wrapper.current!.removeChild(this.game.renderer.domElement)

    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('click', this.handleMapClick)
    // window.removeEventListener('mouseup', this.handleMapClick)
    // window.removeEventListener('mousemove', this.handleMouseMove)
    // window.removeEventListener('touchend', this.handleMapClick)
    window.removeEventListener('touchstart', this.handleMapClick)
    // window.removeEventListener('touchmove', this.handleTouchMove)

    this.controls.removeEventListener('change', this.handleOrbitControlsChange)
  }

  init = () => {
    this.game.width = this.wrapper.current!.clientWidth
    this.game.height = this.wrapper.current!.clientHeight
    this.game.aspect = this.game.width / this.game.height

    this.initScene()
    this.initCamera()
    this.initRenderer()

    this.addHandlers()
  }

  initScene = () => {
    const scene = new THREE.Scene()
    this.game.scene = scene
  }

  initCamera = () => {
    const camera = new THREE.OrthographicCamera(
      -this.d * this.game.aspect,
      this.d * this.game.aspect,
      this.d,
      -this.d,
      0.1,
      100,
    )

    this.game.scene.add(camera)

    camera.position.set(20, 20, 20)
    camera.lookAt(this.game.scene!.position)
    camera.zoom = 1.6
    camera.updateProjectionMatrix()

    this.game.camera = camera
  }

  initRenderer = () => {
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
    })

    // renderer.toneMapping = THREE.ReinhardToneMapping
    // renderer.toneMappingExposure = params.exposure
    renderer.setClearColor(params.clearColor)
    renderer.setSize(this.game.width, this.game.height)
    renderer.outputEncoding = THREE.sRGBEncoding
    // renderer.shadowMap.enabled = true // safary
    // renderer.setPixelRatio(window.devicePixelRatio) // safary

    this.wrapper.current!.appendChild(renderer.domElement)

    this.game.renderer = renderer
  }

  addModel = (model: any) => {
    const loader = new GLTFLoader(this.modelManager)

    const texture = new THREE.TextureLoader().load(
      this.props.hero === Hero.men ? men : women,
    )

    texture.encoding = THREE.sRGBEncoding

    texture.flipY = true

    loader.load(model, (object: GLTF) => {
      console.log('object', object)

      this.model = object.scene

      this.model.position.set(0, 0, 0)

      this.model.rotateY(1.57)
      this.model.translateX(-4.4)
      this.model.translateZ(4.4)

      this.model.translateY(0.8)

      this.model.traverse((n: any) => {
        if (n.isMesh) {
          console.log('n.name', n.name)
          if (
            n.name === 'Character003' ||
            n.name === 'Kitchen_Character' ||
            n.name === 'Bathroom_Character' ||
            n.name === 'Bedroom_Character'
          ) {
            n.material.map = texture
          }
          n.callback = () => this.props.handleObjectClick(n, this.model)
        }
      })

      this.game.scene.add(this.model)
    })
  }

  addModelEffects = (model: any) => {
    const loader = new GLTFLoader(this.modelManager)

    loader.load(model, (object: GLTF) => {
      console.log('addModelEffects object', object)

      this.modelEffect = object.scene

      this.modelEffect.position.set(0, 0, 0)

      this.modelEffect.rotateY(1.57)
      this.modelEffect.translateX(-4.4)
      this.modelEffect.translateZ(4.4)

      this.modelEffect.translateY(0.8)

      this.game.scene.add(this.modelEffect)
    })
  }

  addHandlers = () => {
    window.addEventListener('resize', this.handleResize, false)
    window.addEventListener('click', this.handleMapClick, false)
    // window.addEventListener('mouseup', this.handleMapClick, false)
    // window.addEventListener('mousemove', this.handleMouseMove, false)
    // window.addEventListener('touchend', this.handleMapClick, false)
    window.addEventListener('touchstart', this.handleMapClick)
    // window.addEventListener('touchmove', this.handleTouchMove)

    this.modelManager.onLoad = () => {
      console.log('modelManager Loading complete!')
      this.setState({
        ...this.state,
        modelLoaded: true,
      })
    }

    const controls = new OrbitControls(
      this.game.camera,
      this.game.renderer.domElement,
    )

    controls.screenSpacePanning = true
    controls.enableRotate = false
    controls.enableZoom = false
    controls.enablePan = false

    controls.touches = {
      ONE: THREE.TOUCH.PAN,
      TWO: THREE.TOUCH.DOLLY_PAN,
    }

    controls.mouseButtons = {
      LEFT: THREE.MOUSE.LEFT,
      MIDDLE: THREE.MOUSE.MIDDLE,
      RIGHT: THREE.MOUSE.RIGHT,
    }

    this.controls = controls

    controls.addEventListener('change', this.handleOrbitControlsChange)
  }

  handleOrbitControlsChange = (e) => {
    this.moved = true
  }

  handleResize = (e) => {
    this.game.width = this.wrapper.current!.clientWidth
    this.game.height = this.wrapper.current!.clientHeight
    this.game.aspect = this.game.width / this.game.height

    this.game.camera.left = -this.d * this.game.aspect
    this.game.camera.right = this.d * this.game.aspect
    this.game.camera.updateProjectionMatrix()

    this.game.renderer.setSize(this.game.width, this.game.height)
  }

  handleMapClick = (e) => {
    if (e.target.tagName !== 'CANVAS') {
      return
    }

    if (this.moved) {
      this.moved = false
      return
    }

    e.preventDefault()

    const clientX =
      e.type === 'touchend'
        ? this.lastMove && this.lastMove.touches[0].clientX
        : e.clientX
    const clientY =
      e.type === 'touchend'
        ? this.lastMove && this.lastMove.touches[0].clientY
        : e.clientY

    this.mouse.x = (clientX / this.game.renderer.domElement.clientWidth) * 2 - 1
    this.mouse.y =
      -(clientY / this.game.renderer.domElement.clientHeight) * 2 + 1

    this.raycaster.setFromCamera(this.mouse, this.game.camera)

    const intersects = this.raycaster.intersectObjects(this.model.children)

    if (intersects.length > 0) {
      intersects[0].object.callback()
    }
  }

  handleMouseDown = (e) => {
    this.moved = false
  }

  handleMouseMove = (e) => {
    this.moved = true
  }

  handleTouchStart = (e) => {
    this.lastMove = e
  }

  handleTouchMove = (e) => {
    this.moved = true
    this.lastMove = e
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId!)
  }

  animate = () => {
    this.frameId = window.requestAnimationFrame(this.animate)

    if (this.modelEffect) {
      this.modelEffect.traverse((n: any) => {
        if (n.material && n.material.map) {
          const offsetX = n.material.map.offset.x
          n.material.map.offset.set(offsetX + 0.012, 0)
        }
      })
    }

    this.renderScene()
  }

  renderScene() {
    this.game.renderer.render(this.game.scene, this.game.camera)
  }

  render() {
    return (
      <>
        <Styled.Wrapper ref={this.wrapper} id="gui" />
        {this.state.modelLoaded === false && (
          <Styled.Loader>
            <Spin size="large" />
          </Styled.Loader>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    hero: heroSelector(state),
  }
}

export const Room = connect(mapStateToProps, null)(RoomComponent)
