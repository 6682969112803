import React from 'react'
import { Button, Title, Text } from 'src/components'

import { Prime } from './components'

import { Styled } from './styles'

import { AppState } from 'src/redux'

import { useSelector } from 'react-redux'

import citymobil from './citymobil.png'
import deliveryСlub from './delivery-club.svg'
import disk from './disk.svg'
import megamarket from './megamarket.svg'
import market from './market.svg'
import mobile from './mobile.svg'
import okko from './okko.svg'
import scooter from './scooter.svg'
import pharmacy from './pharmacy.png'
import health from './health.svg'
import sound from './sound.svg'
import prime from './prime.svg'

import { Modals } from 'src/types'

import { useDispatch } from 'react-redux'
import { appModalSelector, appActions } from 'src/redux/ducks/app'

import { TITLES, ITEMS, PRIME_DATA } from './mocks'

const LOGOS = {
  [Modals.okko]: okko,
  [Modals.citymobil]: citymobil,
  [Modals.pharmacy]: pharmacy,
  [Modals.market]: market,
  [Modals.megamarket]: megamarket,
  [Modals.sound]: sound,
  [Modals.scooter]: scooter,
  [Modals.disk]: disk,
  [Modals.health]: health,
  [Modals.mobile]: mobile,
  [Modals.deliveryСlub]: deliveryСlub,
  [Modals.prime]: prime,
}

type Advantage = {
  onClose: VoidFunction
}

export const Advantage: React.FC<any> = (props) => {
  const { onClose } = props

  const dispatch = useDispatch()
  const appModal = useSelector((state: AppState) => appModalSelector(state))

  const handleButtonClick = () => {
    if (appModal.name === Modals.prime) {
      return dispatch(appActions.appModalOpen(Modals.consultant))
    }

    dispatch(appActions.appModalClose())
    dispatch(appActions.setBenefit(appModal.name))
  }

  const handlePrimeClick = () => {
    return dispatch(appActions.appModalOpen(Modals.primePdf))
  }

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Logo>
          <img src={LOGOS[appModal.name]} alt={`логотип ${appModal.name}`} />
        </Styled.Logo>
        <Styled.Title>
          <Title type="h2">{TITLES[appModal.name]}</Title>
        </Styled.Title>
      </Styled.Header>
      <Styled.Content>
        {appModal.name === Modals.prime ? (
          <Prime data={PRIME_DATA} />
        ) : (
          <Styled.List>
            {ITEMS[appModal.name].map((item) => (
              <Styled.Item key={item}>
                <Text type="body2">{item}</Text>
              </Styled.Item>
            ))}
          </Styled.List>
        )}
      </Styled.Content>
      <Styled.Fotter>
        <Styled.Advertising>
          <p>Выгоднее вместе с «Прайм»!</p>
          <img src={prime} alt="логотип Сбер-прайм" />
        </Styled.Advertising>
        <Styled.Controls>
          <Styled.Control>
            {appModal.name === Modals.prime ? (
              <Button onClick={handlePrimeClick}>Подробнее</Button>
            ) : (
              <Button onClick={onClose}>Продолжить экскурсию</Button>
            )}
          </Styled.Control>
          <Styled.Control>
            <Button onClick={handleButtonClick}>
              {appModal.name === Modals.prime
                ? 'Оформить подписку'
                : 'Добавить в Подарки'}
            </Button>
          </Styled.Control>
        </Styled.Controls>
      </Styled.Fotter>
    </Styled.Wrapper>
  )
}
