import styled from 'styled-components'
import { TextProps, AlignType, Type } from './types'

// TODO : разобраться почему без assertion ошибка с "symbol",
type PropsType<T> = {
  [key in T]?: string
  // [key in T as Type | AlignType]?: string
}

const fontSize: PropsType<Type> = {
  body1: '16',
  body2: '16',
  body3: '12',
}

const lineHeight: PropsType<Type> = {
  body1: '21',
  body2: '21',
  body3: '16',
}

const fontWeight: PropsType<Type> = {
  body1: '600',
  body2: '400',
}

const textAlign: PropsType<AlignType> = {
  center: 'center',
  right: 'right',
}

export const TextStyled = styled.p<TextProps>`
  font-family: 'SBSansDisplay';
  font-size: ${(props) => fontSize[props.type as Type]}px;
  line-height: ${(props) => lineHeight[props.type as Type]}px;

  font-style: normal;
  font-weight: ${(props) => fontWeight[props.type as Type]};

  text-align: ${(props) => textAlign[props.align as AlignType] || 'inherit'};
  color: inherit;
`
