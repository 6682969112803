import styled from 'styled-components'
import { colors } from 'src/styles/colors'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 15px 0;
  background-color: ${colors.SNOW};
  text-align: center;
`

const List = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const Item = styled.div`
  width: 33%;
  padding: 0 14px;
  text-align: center;

  color: ${colors.BLACK};

  &:nth-child(7) {
    width: 40%;
  }
  &:last-child {
    width: 50%;
  }
`

const Title = styled.div`
  color: ${colors.GREEN};

  p {
    margin-bottom: 2px;
  }
`

const Text = styled.div`
  margin-bottom: 5px;

  p {
    margin-bottom: 0px;
  }
`

export const Styled = {
  Wrapper,
  List,
  Item,
  Title,
  Text,
}
