import React from 'react'
import { Modal as AntdModal, ModalProps as AntdModalProps } from 'antd'
import { CrossButton } from 'src/components/CrossButton'
import { Styled } from './styles'

type ModalProps = AntdModalProps & {
  onClose: VoidFunction
  hideCloseButton?: boolean
}

export const Modal: React.FC<ModalProps> = (props) => {
  const { onClose, hideCloseButton } = props

  return (
    <AntdModal {...props}>
      <Styled.ModalContent>
        {!hideCloseButton && (
          <Styled.CloseButton>
            <CrossButton onClick={onClose} />
          </Styled.CloseButton>
        )}
        {props.children}
      </Styled.ModalContent>
    </AntdModal>
  )
}
