import React, { useState, useCallback } from 'react'
import { Styled } from './styles'
import { Button, Card, Title, Text } from 'src/components'
import { Justify } from 'src/styles/types'
import { Hero } from 'src/types'
import men from 'src/shared/img/men.svg'
import women from 'src/shared/img/women.svg'

import { useHistory } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { appActions } from 'src/redux/ducks/app'

import { Modals } from 'src/types/modals'

export const ChooseHero: React.FC<{}> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [value, setValue] = useState<Hero | null>(null)

  const play = useCallback(
    (e) => {
      dispatch(appActions.setHero(value))
      dispatch(appActions.appModalOpen(Modals.welcome))
      history.push('/city')

      document.body.classList.add(value === Hero.men ? 'men' : 'women')
    },
    [value, setValue],
  )

  const onChange = useCallback((value) => {
    setValue(value)
  }, [])

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <div className="container">
          <Styled.Inner>
            <Styled.Title>
              <Title type="h1">Выберите аватар</Title>
            </Styled.Title>
            <Styled.List>
              <Card.List
                value={value}
                onChange={onChange}
                justify={Justify['space-between']}
              >
                <Card.Item value={Hero.women}>
                  <Styled.Hero hero={Hero.women}>
                    <img src={women} alt="" />
                  </Styled.Hero>
                </Card.Item>
                <Card.Item value={Hero.men}>
                  <Styled.Hero hero={Hero.men}>
                    <img src={men} alt="" />
                  </Styled.Hero>
                </Card.Item>
              </Card.List>
            </Styled.List>
            <Button disabled={!value} onClick={play}>
              <Text type="body1">Начать игру</Text>
            </Button>
          </Styled.Inner>
        </div>
      </Styled.Container>
    </Styled.Wrapper>
  )
}
