import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Column = styled.div`
  flex-shrink: 0;
  width: 50%;

  &:last-child {
    width: 47%;
  }
`

const Item = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 10px;
  text-align: left;

  p {
    margin-bottom: 0;
    opacity: ${(props) => (props.checked ? 1 : 0.4)};
  }

  img {
    opacity: ${(props) => (props.checked ? 1 : 0.4)};
  }
`

const Logo = styled.div`
  position: relative;
  top: -2px;
  width: 20px;
  margin-right: 8px;
`

const ItemContent = styled.div``

const Title = styled.div`
  display: flex;
  margin-bottom: 1px;

  img {
    width: 15px;
    margin-left: 10px;
    position: relative;
    top: -2px;
  }
`

export const Styled = {
  Wrapper,
  Column,
  Item,
  Logo,
  ItemContent,
  Title,
}
