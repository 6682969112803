import React, { useCallback } from 'react'
import { Modal } from 'src/components'
import { useSelector, useDispatch } from 'react-redux'
import { appModalSelector, appActions } from 'src/redux/ducks/app'
import { Modals } from 'src/types/modals'
import { AppState } from 'src/redux'

import {
  Advantage,
  Consultant,
  MinusChannel,
  Music,
  Visit,
  Welcome,
  WelcomeModal,
  PrimePdf,
  SberPortal,
} from '../'

const ModalContentMap = {
  [Modals.okko]: (props: any) => <Advantage {...props} />,
  [Modals.citymobil]: (props: any) => <Advantage {...props} />,
  [Modals.pharmacy]: (props: any) => <Advantage {...props} />,
  [Modals.market]: (props: any) => <Advantage {...props} />,
  [Modals.megamarket]: (props: any) => <Advantage {...props} />,
  [Modals.sound]: (props: any) => <Advantage {...props} />,
  [Modals.scooter]: (props: any) => <Advantage {...props} />,
  [Modals.disk]: (props: any) => <Advantage {...props} />,
  [Modals.health]: (props: any) => <Advantage {...props} />,
  [Modals.mobile]: (props: any) => <Advantage {...props} />,
  [Modals.deliveryСlub]: (props: any) => <Advantage {...props} />,

  [Modals.prime]: (props: any) => <Advantage {...props} />,
  [Modals.primePdf]: (props: any) => <PrimePdf {...props} />,

  [Modals.consultant]: (props: any) => <Consultant {...props} />,
  [Modals.minusChannel]: (props: any) => <MinusChannel {...props} />,
  [Modals.music]: (props: any) => <Music {...props} />,

  [Modals.visit]: (props: any) => <Visit {...props} />,

  [Modals.welcome]: (props: any) => <Welcome {...props} />,

  [Modals.welcomeKitchen]: (props: any) => <WelcomeModal {...props} />,
  [Modals.welcomeBathroom]: (props: any) => <WelcomeModal {...props} />,
  [Modals.welcomeBedroom]: (props: any) => <WelcomeModal {...props} />,
  [Modals.welcomeLivingRoom]: (props: any) => <WelcomeModal {...props} />,

  [Modals.sberPortal]: (props: any) => <SberPortal {...props} />,
}

export const AppModal: React.FC<any> = (props) => {
  const appModal = useSelector((state: AppState) => appModalSelector(state))
  const dispatch = useDispatch()

  const closeModal = useCallback(() => {
    if (appModal.name! === Modals.visit) {
      dispatch(appActions.setLastAction(null))
    }

    dispatch(appActions.appModalClose())
  }, [appModal, dispatch])

  if (!appModal.visible) {
    return null
  }

  const hideCloseButton = [
    Modals.welcomeKitchen,
    Modals.welcomeBathroom,
    Modals.welcomeBedroom,
    Modals.welcomeLivingRoom,
  ].includes(appModal.name!)

  return (
    <Modal
      {...props}
      visible={appModal.visible}
      onClose={closeModal}
      centered={true}
      footer={null}
      className={`modal-${appModal.name}`}
      hideCloseButton={hideCloseButton}
    >
      {ModalContentMap[appModal.name!]({
        onClose: closeModal,
      })}
    </Modal>
  )
}
