import styled from 'styled-components'
import cart from './game-cart-human.png'

const ProgressBar = styled.div`
  height: 16px;
  width: calc(100% - 16px - 40px);
  margin-right: 16px;
  margin-top: 20px;

  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(16px);
  background: #ffffff;

  border-radius: 16px;
`

const Progress = styled.div`
  position: relative;

  height: 100%;
  width: ${(props) => props.width || '0%'};
  max-width: 100%;

  background: linear-gradient(41.2deg, #21a038 20.32%, #00d900 74.23%);
  border-radius: 16px;

  transition: 0.5s;

  &::after {
    content: '';

    position: absolute;

    top: -12px;
    right: -20px;

    display: block;

    width: 40px;
    height: 40px;

    background-image: url(${cart});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px 40px;
  }
`

export const Styled = {
  ProgressBar,
  Progress,
}
