import React from 'react'
import { Button, Title } from 'src/components'
import { Styled } from './styles'
import consultantMen from './consultant-men.svg'
import consultantWomen from './consultant-women.svg'
import { useSelector } from 'react-redux'
import { heroSelector } from 'src/redux/ducks/app'
import { Hero } from 'src/types'

type ConsultantProps = {
  onClose: VoidFunction
}

export const Consultant: React.FC<ConsultantProps> = (props) => {
  const { onClose } = props
  const hero = useSelector((state) => heroSelector(state))

  return (
    <Styled.Wrapper>
      <Styled.Title>
        <Title type="h1">
          Подойдите к консультанту для оформления подписки
        </Title>
      </Styled.Title>
      <Styled.Consultant>
        <Styled.ConsultantImage
          src={hero === Hero.men ? consultantMen : consultantWomen}
          alt="консультант"
        />
      </Styled.Consultant>
      <Button onClick={onClose}>Закрыть приложение (?)</Button>
    </Styled.Wrapper>
  )
}
