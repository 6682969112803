import React from 'react'
import { Route } from 'react-router-dom'
import { RouteType } from 'types/router'

type RouteWithSubRoutesProps = RouteType & {
  routeProps?: any
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
export function RouteWithSubRoutes(route: RouteWithSubRoutesProps) {
  const { routeProps = {} }: any = route

  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} {...routeProps} />
      )}
    />
  )
}
