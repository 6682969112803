import React from 'react'
import { render } from 'react-dom'
import { App } from './App'
import './fonts/SBSansDisplay-Regular.ttf'
import './fonts/SBSansDisplay-SemiBold.ttf'
import './fonts/SBSansText-Regular.ttf'
import './fonts/SBSansText-SemiBold.ttf'
import 'styles/normalize.css'
import 'antd/dist/antd.css'
import 'styles/main.scss'

render(<App />, document.getElementById('root'))
