import styled from 'styled-components'
import { colors } from 'src/styles/colors'

const CardList = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'center'};
  width: 100%;
`

const Card = styled.div`
  flex-basis: 0;
  flex-grow: 1;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 176px;

  margin-right: 16px;

  padding: 11px;
  padding-top: 16px;

  padding-right: 13px;
  padding-left: 13px;

  border-radius: 8px;
  background-color: rgba(${colors.WHITE_RGB}, 0.7);
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);

  overflow: hidden;

  border: ${(props) => (props.selected ? `3px solid ${colors.GREEN}` : '')};

  @media (max-height: 374px) {
    padding: 11px;
  }

  p {
    margin-bottom: 13px;

    @media (max-height: 374px) {
      margin-bottom: 0;
    }
  }

  img {
    margin-bottom: 11px;
  }

  &:last-child {
    margin-right: 0;
  }
`

const CardInner = styled.div`
  height: 100%;
  border-radius: 8px;
  background-color: rgba(${colors.WHITE_RGB}, 1);
`

const ItemImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 179px;
  max-height: 100%;

  @media (max-height: 374px) {
    height: 150px;
  }

  img {
    max-height: 100%;
  }
`

export const Styled = {
  CardList,
  Card,
  CardInner,
  ItemImage,
}
