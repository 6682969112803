import styled from 'styled-components'

const Wrapper = styled.div`
  z-index: 10;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 40px;
  padding: 0 24px;
`

const CloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 24px;
`

export const Styled = {
  Wrapper,
  CloseButton,
}
