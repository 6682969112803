import React from 'react'
import { useSelector } from 'react-redux'
import { heroSelector } from 'src/redux/ducks/app'
import { Styled } from './styles'
import { Modal, Button, Title, Text } from 'src/components'

type Props = {
  onClose: VoidFunction
  visible: boolean
}

export const WelcomeModal: React.FC<Props> = (props) => {
  const { onClose } = props
  const hero = useSelector((state) => heroSelector(state))

  return (
    <Modal
      {...props}
      hideCloseButton={true}
      centered={true}
      footer={null}
      className={'modal-welcome'}
    >
      <Styled.Wrapper hero={hero}>
        <Styled.Content>
          <Styled.Title>
            <Title type="h1">Онлайн-кинотеатр - это просто!</Title>
          </Styled.Title>
          <Text type="body2">
            Посмотреть кино в онлайн-кинотеатре <Text type="body1">«Okko»</Text>{' '}
            так же легко, как сыграть в игру.
          </Text>

          <Text type="body2">
            Сперва нажми на <Text type="body1">одну</Text> картинку, а потом на{' '}
            <Text type="body1">другую,</Text> и они поменяются местами.
          </Text>

          <Text type="body2">
            Собери цельное изображение сервиса <Text type="body1">«Okko»,</Text>{' '}
            и я расскажу тебе о его преимуществах.
          </Text>
          <Styled.Button>
            <Button onClick={onClose}>Начать игру</Button>
          </Styled.Button>
        </Styled.Content>
      </Styled.Wrapper>
    </Modal>
  )
}
