export const colors: {
  [key: string]: string
} = {
  GREEN: '#21A038',
  GREEN_RGB: '33, 160, 56',

  GREY: '#EEEEEE',
  GREY_RGB: '238, 238, 238',

  BLUE: '0, 135, 205',
  ARTIC: '66, 227, 180',
  EMERALD: '33, 186, 114',
  HERBAL: '#00D900',
  HERBAL_RGB: '0, 217, 0',
  SPRING: '160, 231, 32',

  SOLAR: '250, 237, 0',
  ORANGE: '255, 128, 0',
  PINK: '#FF0066',
  PINK_RGB: '255, 0, 102',

  WHITE: '#FFFFFF',
  WHITE_RGB: '255, 255, 255',

  BLACK: '#000',
  BLACK_RGB: '0, 0, 0',

  SNOW: '#EEF3FF',
  SNOW_RGB: '238, 243, 255',
  GRAPHITE: '#334047',
  GRAPHITE_RGB: '51, 63, 72',

  GRADIENT_MAIN:
    'linear-gradient(90deg, #21A038 5.84%, #A3CD39 24.82%, #F2E913 44%, #A3CD39 61.4%, #0FA8E0 77.68%, #0C8AD0 94.67%)',
  GRADIENT_DUOTONE: 'linear-gradient(41.2deg, #21A038 20.32%, #00D900 74.23%)',
  GRADIENT_SECONDARY:
    'linear-gradient(90deg, #21A038 5.84%, #00D900 44%, #FAED00 94.67%)',

  GRADIENT_BLUE: 'linear-gradient(90.28deg, #B7CFE7 5.25%, #A0C5D7 98.48%)',

  GRADIENT_BLUE_LIGHT:
    'linear-gradient(90.28deg, #92E8E9 5.25%, #92E8E9 98.48%)',

  GRADIENT_NOT_BORING:
    'linear-gradient(79.08deg, #F1F6D5 0%, #D9F0EE 50.31%, #CEE8F6 100%)',
}
