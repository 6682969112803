import { css } from 'styled-components'
import { shadows } from './shadows'
import { colors } from './colors'

export const CardStyle = css`
  box-shadow: ${shadows.DROP_SHADOW};

  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);

  background: rgba(${colors.WHITE_RGB}, 0.95);

  border-radius: 8px;

  @supports (backdrop-filter: none) {
    background: rgba(${colors.WHITE_RGB}, 0.85);
    backdrop-filter: blur(16px);
  }
`

export const CardStyleActive = css`
  cursor: pointer;
  transition: all 0.2s;

  &:hover,
  &:active,
  &:focus {
    background: rgba(${colors.GREY_RGB}, 0.95);

    @supports (backdrop-filter: none) {
      background: rgba(${colors.GREY_RGB}, 0.85);
    }
  }
`
