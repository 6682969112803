import styled from 'styled-components'
import { colors } from 'src/styles/colors'

const Text = styled.div`
  margin-bottom: 46px;
`

const TextSecond = styled.div`
  margin-bottom: 40px;
`

const Icon = styled.img`
  position: relative;
  top: -2px;
  width: 31px;
  margin-left: 7px;
`

const Score = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 33px;
  font-weight: 600;
  font-family: 'SBSansDisplay';
  font-size: 80px;
  line-height: 80px;

  img {
    margin-top: 7px;
  }
`

const ScoreLeft = styled.div`
  margin-right: 53px;

  span {
    color: ${colors.GREEN};
  }
`

const ScoreRight = styled.div`
  span {
    color: ${colors.PINK};
  }
`

const Controls = styled.div`
  display: flex;
  padding-top: 56px;
`

const Button = styled.div`
  &:first-child {
    margin-right: 16px;
  }
`

export const Styled = {
  Text,
  TextSecond,
  Icon,
  Score,
  ScoreLeft,
  ScoreRight,
  Controls,
  Button,
}
