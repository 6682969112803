import React from 'react'
import { connect } from 'react-redux'
import { appActions } from 'src/redux/ducks/app'
import { gamesActions } from 'src/redux/ducks/games'
import { Modals } from 'src/types/modals'
import './game.scss'
import { v4 as uuidv4 } from 'uuid'
import cn from 'classnames'
import { EndGameModal, WelcomeModal } from './components'
import { CrossButton } from 'src/components'

const getInitialState = () => ({
  gameState: {
    currentProgress: 0,
    ClickCount: 0,
    ClickPerGreen: 0,
    ClickPerRed: 0,
    StartTime: 0,
    elementsInScene: [],
  },
  gameSettings: {
    GlobalIntervalTick: 501,
    MinElements: 2,
    MaxElements: 5,
    ProgressPerClick: 5,
    ElementLiveTime: 2000,
  },
  finished: false,
  welcomeModalVisible: false,
})

type TaxiGameComponentProps = {
  closeGame: VoidFunction
}

type Element = {
  id: string
  type: string
  clicked?: boolean
  left: number
  top: number
  opacity?: number
}

type TaxiGameComponentState = {
  gameState: {
    currentProgress: number
    ClickCount: number
    ClickPerGreen: number
    ClickPerRed: number
    StartTime: number
    elementsInScene: Element[]
  }
  gameSettings: {
    GlobalIntervalTick: number
    MinElements: number
    MaxElements: number
    ProgressPerClick: number
    ElementLiveTime: number
  }
  finished: boolean
  welcomeModalVisible: boolean
}

class TaxiGameComponent extends React.Component<
  TaxiGameComponentProps,
  TaxiGameComponentState
> {
  interval: NodeJS.Timer | null = null
  gameSceneRef: React.RefObject<HTMLDivElement>

  constructor(props: TaxiGameComponentProps) {
    super(props)

    this.state = getInitialState()

    this.interval = null

    this.gameSceneRef = React.createRef()
  }

  componentDidMount = () => {
    this.showWelcomeModal()
  }

  componentWillUnmount = () => {
    clearInterval(this.interval!)
  }

  showWelcomeModal = () => {
    this.setState({
      ...this.state,
      welcomeModalVisible: true,
    })
  }

  closeWelcomeModal = () => {
    this.setState(
      {
        ...this.state,
        welcomeModalVisible: false,
      },
      () => {
        this.initGame()
      },
    )
  }

  initGame = () => {
    this.interval = setInterval(() => {
      this.onTick()
    }, this.state.gameSettings.GlobalIntervalTick)
    this.onInitGameScene()
  }

  onInitGameScene = () => {
    this.setState({
      ...this.state,
      gameState: {
        ...this.state.gameState,
        currentProgress: 0,
        ClickCount: 0,
        ClickPerGreen: 0,
        ClickPerRed: 0,
        StartTime: Date.now(),
      },
      finished: false,
    })
  }

  onTick = () => {
    const { gameState, gameSettings } = this.state

    let needCreate = gameSettings.MinElements - gameState.elementsInScene.length

    if (needCreate <= 0) {
      needCreate = 0
    }

    needCreate =
      needCreate +
      (Date.now() % (gameSettings.MaxElements - gameSettings.MinElements + 1))

    if (
      needCreate + gameState.elementsInScene.length >
      gameSettings.MaxElements
    ) {
      needCreate =
        needCreate +
        (gameSettings.MaxElements -
          (needCreate + gameState.elementsInScene.length))
    }

    for (let index = 0; index < needCreate; index++) {
      this.spawnElement()

      if (index == 0 && needCreate > 1) {
        setTimeout(this.onTick, 497 + Number(String(Date.now()).substr(-3)))
      }

      break
    }
  }

  gameFinish = () => {
    clearInterval(this.interval!)
    this.setState((state) => ({
      ...state,
      finished: true,
    }))
  }

  onClickPerRed = (item: Element) => {
    this.destroyElement(item.id)
  }

  onClickButton = (item: Element) => {
    if (item.type === 'red') {
      this.onClickPerRed(item)
      this.setState((state) => ({
        ...state,
        gameState: {
          ...state.gameState,
          ClickPerRed: state.gameState.ClickPerRed + 1,
        },
      }))
    }

    if (item.type === 'green') {
      this.onClickPerGreen(item)
      this.setState((state) => ({
        ...state,
        gameState: {
          ...state.gameState,
          ClickPerGreen: state.gameState.ClickPerGreen + 1,
        },
      }))
    }
  }

  onClickPerGreen = (item: Element) => {
    const { gameState, gameSettings } = this.state

    if (item.clicked) {
      return
    }

    const newElements = this.state.gameState.elementsInScene.map(
      (targetItem) => {
        if (targetItem.id === item.id) {
          return {
            ...item,
            clicked: true,
          }
        }

        return item
      },
    )

    this.setState((state) => ({
      ...state,
      gameState: {
        ...state.gameState,
        elementsInScene: newElements,
      },
    }))

    if (gameState.currentProgress < 100) {
      const currentProgress =
        gameState.currentProgress + gameSettings.ProgressPerClick

      this.setState((state) => ({
        ...state,
        gameState: {
          ...state.gameState,
          currentProgress,
        },
      }))

      if (currentProgress >= 100) {
        this.gameFinish()
      }
    }

    this.destroyElement(item.id)
  }

  spawnElement = () => {
    const { gameState } = this.state

    const circleItem: Element = {
      id: uuidv4(),
    }

    if (Date.now() % 2 == 0) {
      circleItem.type = 'red'
    } else {
      circleItem.type = 'green'
    }

    while (true) {
      circleItem.left =
        ((Number(String(Date.now()).substr(-4)) +
          gameState.currentProgress +
          gameState.elementsInScene.length) %
          this.gameSceneRef.current!.clientWidth) -
        this.gameSceneRef.current!.offsetLeft

      circleItem.top =
        (Number(String(Date.now()).substr(-4)) +
          gameState.currentProgress +
          gameState.elementsInScene.length) %
        (this.gameSceneRef.current!.clientHeight -
          72 -
          this.gameSceneRef.current!.offsetTop)

      const collisionEls = gameState.elementsInScene.filter((elInScene) => {
        const a = elInScene.left - circleItem.left
        const b = elInScene.top - circleItem.top

        return Math.sqrt(a * a + b * b) < 72
      })

      if (collisionEls.length == 0) {
        break
      }
    }

    this.setState(
      (state) => {
        return {
          ...state,
          gameState: {
            ...state.gameState,
            elementsInScene: [...state.gameState.elementsInScene, circleItem],
          },
        }
      },
      () => {
        this.showCircle(circleItem.id)
      },
    )
  }

  showCircle = (id: string) => {
    const { gameState, gameSettings } = this.state
    const element = gameState.elementsInScene.filter((item) => item.id === id)
    const pos = gameState.elementsInScene.findIndex((item) => item.id === id)

    const newElements = gameState.elementsInScene.slice()
    newElements.splice(pos, 1)
    newElements.push({
      ...element[0],
      opacity: 1,
    })

    this.setState(
      {
        ...this.state,
        gameState: {
          ...this.state.gameState,
          elementsInScene: newElements,
        },
      },
      () => {
        setTimeout(() => this.hideCircle(id), gameSettings.ElementLiveTime)
      },
    )
  }

  hideCircle = (id: string) => {
    const { gameState, gameSettings } = this.state

    const element = gameState.elementsInScene.filter((item) => item.id === id)
    const pos = gameState.elementsInScene.findIndex((item) => item.id === id)

    if (pos != -1) {
      const newElements = gameState.elementsInScene.slice()

      newElements.splice(pos, 1)
      newElements.push({
        ...element[0],
        opacity: 0,
      })

      this.setState(
        {
          ...this.state,
          gameState: {
            ...this.state.gameState,
            elementsInScene: newElements,
          },
        },
        () => {
          setTimeout(
            () => this.destroyElement(id),
            gameSettings.ElementLiveTime,
          )
        },
      )
    }
  }

  destroyElement = (id: string) => {
    const { gameState } = this.state

    const pos = gameState.elementsInScene.findIndex((item) => item.id === id)

    if (pos != -1) {
      const newElements = gameState.elementsInScene.slice()

      newElements.splice(pos, 1)

      this.setState((state) => ({
        ...state,
        gameState: {
          ...state.gameState,
          elementsInScene: newElements,
        },
      }))
    }
  }

  closeGame = () => {
    this.props.closeGame()
  }

  restart = () => {
    this.initGame()
  }

  openAdvantageModal = () => {
    this.props.openModal(Modals.citymobil)
    this.closeGame()
  }

  render() {
    const barWidth = this.state.gameState.currentProgress + '%'

    return (
      <div id="taxi-game">
        <div className="top-line">
          <div className="progress-bar">
            <div className="progress" style={{ width: barWidth }}></div>
          </div>
          <div className="close-button icon-button" onClick={this.closeGame}>
            <CrossButton onClick={this.closeGame} />
          </div>
        </div>
        <div className="game-wrapper">
          <div className="game-scene" ref={this.gameSceneRef}>
            {this.state.gameState.elementsInScene.map((item: Element) => (
              <div
                key={item.id}
                id={item.id}
                className={cn({
                  'game-button': true,
                  red: item.type === 'red',
                  green: item.type === 'green',
                })}
                onClick={() => this.onClickButton(item)}
                onTouchStart={() => this.onClickButton(item)}
                style={{
                  left: item.left,
                  top: item.top,
                  opacity: item.opacity || 0,
                  pointerEvents: item.opacity ? 'auto' : 'none',
                }}
              ></div>
            ))}
          </div>
        </div>
        <EndGameModal
          visible={this.state.finished}
          scoreGreen={this.state.gameState.ClickPerGreen}
          scoreRed={this.state.gameState.ClickPerRed}
          restart={this.restart}
          next={this.openAdvantageModal}
        />
        <WelcomeModal
          visible={this.state.welcomeModalVisible}
          onClose={this.closeWelcomeModal}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (name: Modals) => dispatch(appActions.appModalOpen(name)),
    closeGame: () => dispatch(gamesActions.hideTaxiGame()),
  }
}

export const TaxiGame = connect(null, mapDispatchToProps)(TaxiGameComponent)
