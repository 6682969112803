import React, { useState, useCallback, useEffect } from 'react'
import { Room } from '../'
import { Models } from '../../types'
import { FridgeModal } from './components'
import { useSelector } from 'react-redux'
import { lastActionSelector } from 'src/redux/ducks/app'

export const Kitchen: React.FC = () => {
  const lastAction = useSelector((state) => lastActionSelector(state))

  const [isFridgeVisible, setIsFridgeVisible] = useState(false)
  const toggleFridge = useCallback(() => {
    setIsFridgeVisible(!isFridgeVisible)
  }, [isFridgeVisible, setIsFridgeVisible])

  useEffect(() => {
    if (lastAction) {
      setIsFridgeVisible(true)
    }
  }, [])

  const objectClickHandler = (mesh, model) => {
    console.log('mesh', mesh)
    switch (mesh.name) {
      case 'Plane018': {
        toggleFridge()
        break
      }

      default: {
        break
      }
    }
  }

  return (
    <>
      <Room model={Models.kitchen} handleObjectClick={objectClickHandler} />

      {isFridgeVisible && (
        <FridgeModal visible={isFridgeVisible} onClose={toggleFridge} />
      )}
    </>
  )
}
