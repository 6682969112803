import React from 'react'
import { Styled } from './styles'

type CardProps = {
  value?: any
}

export const Card: React.FC<CardProps> = (props) => {
  return <Styled.Card {...props}>{props.children}</Styled.Card>
}

export const ItemImage: React.FC<CardProps> = (props) => {
  return <Styled.ItemImage {...props}>{props.children}</Styled.ItemImage>
}
