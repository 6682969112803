import React from 'react'
import { LoaderStyled } from './styles'
import { Spin } from 'antd'

export const Loader: React.FC<{}> = (props) => {
  return (
    <LoaderStyled>
      <Spin size="large" />
    </LoaderStyled>
  )
}
