import styled from 'styled-components'
import { colors } from 'src/styles/colors'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 59px;
  max-width: 100%;
  max-height: 100%;

  @media (max-height: 400px), (max-width: 700px) {
    width: 44px;
    height: 44px;
  }
`

const Badge = styled.span`
  position: absolute;

  z-index: 1;

  top: -1px;
  left: -2px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  font-family: 'SBSansDisplay';
  font-weight: 600;
  font-size: ${(props) => (props.small ? '12px' : '18px')};
  line-height: 21px;

  border: 2px solid ${colors.WHITE};
  border-radius: 50%;
  background-color: ${colors.PINK};

  transform: translate(-50%, -50%);
`

export const Styled = {
  Wrapper,
  Badge,
}
