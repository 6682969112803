import styled from 'styled-components'
import { textShadow } from 'src/styles/text'
import { colors } from 'src/styles/colors'
import check from 'src/shared/img/check-circle.svg'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(392px - 32px);

  @media (max-height: 413px) {
    min-height: auto;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 13px;

  @media (max-width: 700px), (max-height: 340px) {
    padding-right: 25px;
  }
`

export const Title = styled.div`
  ${textShadow(colors.GREEN)}

  p {
    margin-bottom: 0;
    text-align: right;

    @media (max-width: 700px), (max-height: 340px) {
      font-size: 18px;
    }
  }
`

export const Content = styled.div`
  margin-bottom: 5px;
`

export const Logo = styled.div``

export const List = styled.div``

export const Item = styled.div`
  margin: 0 -15px;

  padding: 9px 15px 7px;
  padding-left: 56px;

  text-align: left;

  background-image: url(${check});
  background-repeat: no-repeat;
  background-position: left 16px center;

  @media (max-height: 413px) {
    padding: 5px 15px 3px;
    padding-left: 46px;
  }

  &:nth-child(odd) {
    background-color: ${colors.WHITE};
  }

  p {
    margin-bottom: 0;

    @media (max-height: 413px) {
      font-size: 14px;
    }
  }
`

export const Fotter = styled.div`
  margin-top: auto;
`

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Control = styled.div`
  width: calc(50% - 8px);

  button {
    min-width: auto;
    width: 100%;
  }

  &:first-child {
    margin-right: 16px;
  }
`

export const Advertising = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;

  p {
    margin-right: 8px;
    margin-bottom: 0;

    font-size: 12px;
    font-weight: 600;

    line-height: 21px;

    color: ${colors.GREEN};
  }

  img {
    max-width: 90px;
  }
`

export const Styled = {
  Wrapper,
  Header,
  Title,
  Content,
  Logo,
  List,
  Item,
  Fotter,
  Controls,
  Control,
  Advertising,
}
