import styled from 'styled-components'
import { colors } from 'src/styles/colors'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.SNOW};
`

const Loader = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${colors.SNOW};
`

const BackButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`

const Controls = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
`

const Control = styled.div`
  margin: 0 8px;

  button {
    @media (max-width: 670px) {
      min-width: 152px;
    }
  }
`

export const Styled = {
  Wrapper,
  Loader,
  BackButton,
  Controls,
  Control,
}
