import React from 'react'
import { connect } from 'react-redux'
import { appActions } from 'src/redux/ducks/app'

import { WelcomeModal } from './components'

import { ProgressBar, Header, EndGameModal } from '../components'

import { Modals } from 'src/types/modals'

import './styles.scss'

function randomInteger(min, max) {
  // случайное число от min до (max+1)
  const rand = min + Math.random() * (max + 1 - min)
  return Math.floor(rand)
}

const uiSettings = {
  buttons: 136,
  paddingPage: 24,
}

const getInitialState = () => ({
  gameState: {
    currentScore: 0,
    maxScore: 10,
    fps: 60,
    speed: 15,
    delaySpawn: 1000,
    lastSpawn: 500,
    startTime: Date.now(),
    lastTick: Date.now(),
  },
  enemyInSceneList: [],
  finished: false,
  welcomeModalVisible: false,
})

type Enemy = HTMLElement & {
  spawned?: boolean
  opacity?: boolean
}

type GameState = {
  currentScore: number
  maxScore: number
  fps: number
  speed: number
  delaySpawn: number
  lastSpawn: number
  startTime: Date
  lastTick: Date
}

type FoodGameComponentProps = {
  closeGame: VoidFunction
}

type FoodGameComponentState = {
  gameState: GameState
  enemyInSceneList: Enemy[]
  finished: boolean
  welcomeModalVisible: boolean
}

class FoodGameComponent extends React.Component<
  FoodGameComponentProps,
  FoodGameComponentState
> {
  gameScene = null
  enemyBlocks = null
  interval: NodeJS.Timer | null = null
  gameSceneRef: React.RefObject<HTMLDivElement>
  enemyBlocksRef: React.RefObject<HTMLDivElement>

  constructor(props: FoodGameComponentProps) {
    super(props)

    this.state = getInitialState()

    this.gameSceneRef = React.createRef()
    this.enemyBlocksRef = React.createRef()
  }

  componentDidMount = () => {
    this.showWelcomeModal()
  }

  componentWillUnmount = () => {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  showWelcomeModal = () => {
    this.setState({
      ...this.state,
      welcomeModalVisible: true,
    })
  }

  closeWelcomeModal = () => {
    this.setState({
      ...this.state,
      welcomeModalVisible: false,
    })
    this.initGame()
  }

  initGame = (): void => {
    this.interval = setInterval(() => {
      try {
        this.OnGameTick(Date.now() - this.state.gameState.lastTick)
      } catch (e) {
        console.error(e)
      }
      this.setState({
        ...this.state,
        gameState: {
          ...this.state.gameState,
          lastTick: Date.now(),
        },
      })
    }, 1000 / this.state.gameState.fps)
  }

  Restart = () => {
    this.setState(getInitialState())
    this.initGame()
  }

  OnGameTick = (deltaTime: number): void => {
    if (this.CanNewSpawnEnemy()) {
      this.SpawnEnemy()
    }

    for (const enemy of this.state.enemyInSceneList) {
      this.OnEnemyUpdate(deltaTime, enemy)
    }
  }

  CanNewSpawnEnemy = () => {
    return (
      Date.now() - this.state.gameState.lastSpawn >
      this.state.gameState.delaySpawn
    )
  }

  SpawnEnemy = () => {
    const newEnemy: Enemy = document.createElement('div')
    const salt =
      Date.now() +
      this.state.enemyInSceneList.length +
      this.state.gameState.currentScore
    let leftPosition =
      Date.now() %
      (this.gameSceneRef.current!.clientWidth -
        uiSettings.buttons -
        uiSettings.paddingPage)
    if (leftPosition < uiSettings.buttons) {
      leftPosition = uiSettings.buttons
    }

    newEnemy.classList.add('enemy-block')
    newEnemy.classList.add('enemy-' + randomInteger(1, 9))
    newEnemy.style.top = '0px'
    newEnemy.style.left = leftPosition + 'px'

    newEnemy.spawned = true
    newEnemy.opacity = true

    newEnemy.addEventListener('click', () => {
      this.UpScore()
      this.RemoveEnemy(newEnemy)
    })

    newEnemy.addEventListener('touchstart', () => {
      this.UpScore()
      this.RemoveEnemy(newEnemy)
    })

    this.enemyBlocksRef.current!.appendChild(newEnemy)
    this.setState({
      ...this.state,
      gameState: {
        ...this.state.gameState,
        lastSpawn: Date.now(),
      },
      enemyInSceneList: [...this.state.enemyInSceneList, newEnemy],
    })
  }

  OnEnemyUpdate = (deltaTime: number, enemy: any) => {
    const top = Number(enemy.style.top.substr(0, enemy.style.top.length - 2))
    const newTop = top + deltaTime * (this.state.gameState.speed / 100)

    if (newTop > this.gameSceneRef.current!.clientHeight) {
      this.RemoveEnemy(enemy)
      return
    }

    if (enemy.spawned == true) {
      enemy.spawned = false
    } else if (enemy.opacity == true) {
      enemy.opacity = false
      enemy.style.opacity = 1
    }

    enemy.style.top = newTop + 'px'
  }

  RemoveEnemy = (enemy: any) => {
    const index = this.state.enemyInSceneList.indexOf(enemy)
    const newEnemyInSceneList = this.state.enemyInSceneList.slice()
    newEnemyInSceneList.splice(index, 1)
    this.setState({
      ...this.state,
      enemyInSceneList: newEnemyInSceneList,
    })
    enemy.remove()
  }

  UpScore = () => {
    this.setState({
      ...this.state,
      gameState: {
        ...this.state.gameState,
        currentScore: this.state.gameState.currentScore + 1,
      },
    })

    if (this.state.gameState.currentScore >= this.state.gameState.maxScore) {
      this.GameFinish()
    }
  }

  GameFinish = () => {
    clearInterval(this.interval!)

    this.state.enemyInSceneList.forEach((item) => this.RemoveEnemy(item))

    this.setState({
      ...this.state,
      finished: true,
    })
  }

  openAdvantageModal = () => {
    this.props.openModal(Modals.scooter)
    this.GameClose()
  }

  GameClose = () => {
    this.props.closeGame()
  }

  render() {
    const barWidth =
      (this.state.gameState.currentScore * 100) /
        this.state.gameState.maxScore +
      '%'

    return (
      <div id="game-blog-food">
        <Header onClose={this.GameClose}>
          <ProgressBar barWidth={barWidth} />
        </Header>
        <div className="game-wrapper">
          <div className="game-scene" ref={this.gameSceneRef}>
            <div className="enemy-blocks" ref={this.enemyBlocksRef}></div>
          </div>
        </div>
        <div className="score-line">
          <span className="current">{this.state.gameState.currentScore}</span> /{' '}
          <span className="max">{this.state.gameState.maxScore}</span>
        </div>
        <EndGameModal
          visible={this.state.finished}
          score={this.state.gameState.currentScore}
          maxScore={this.state.gameState.maxScore}
          restart={this.Restart}
          next={this.openAdvantageModal}
        />
        <WelcomeModal
          visible={this.state.welcomeModalVisible}
          onClose={this.closeWelcomeModal}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (name: Modals) => dispatch(appActions.appModalOpen(name)),
  }
}

export const FoodGame = connect(null, mapDispatchToProps)(FoodGameComponent)
