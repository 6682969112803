import styled from 'styled-components'
import { colors } from 'styles/colors'
import { textShadow } from 'styles/text'

const ModalContent = styled.div`
  // padding: 20px 16px 16px;
  text-align: center;
  color: ${colors.GRAPHITE};

  p[type='h1'] {
    ${textShadow(colors.GREEN)}
  }
`

const CloseButton = styled.div`
  position: absolute;

  top: -20px;
  right: -48px; // width = 40, margin = 8

  @media (max-height: 480px) {
    top: 0;
  }

  @media (max-width: 700px), (max-height: 340px) {
    top: 15px;
    right: 10px;
  }

  & > div {
    @media (max-width: 700px), (max-height: 340px) {
      width: 20px;
      height: 20px;
      background: none;
      box-shadow: none;
    }
  }
`

export const Styled = {
  ModalContent,
  CloseButton,
}
