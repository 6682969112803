import React from 'react'
import { BackButton } from 'src/components'
import { Styled } from './styles'

import { useHistory } from 'react-router-dom'

import { RouteWithSubRoutes } from 'src/components'
import { RouteType } from 'types/router'

type HomeScreenProps = {
  routes: RouteType[]
}

export const Home: React.FC<HomeScreenProps> = ({ routes }) => {
  const history = useHistory()

  return (
    <Styled.Wrapper>
      <Styled.BackButton>
        <BackButton onClick={() => history.goBack()} />
      </Styled.BackButton>

      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Styled.Wrapper>
  )
}
