import React, { useState, useCallback, useEffect } from 'react'
import { CrossButton } from 'src/components'
import { ModalProps } from 'antd'
import { Styled } from './styles'

import { FridgeModal } from './components'

import fridge from './/fridge.svg'
import recipeImage from './recipe.svg'
import bread from 'src/shared/img/food/bread.svg'
import cheese from 'src/shared/img/food/cheese.svg'
import tomato from 'src/shared/img/food/tomato.svg'
import zucchini from 'src/shared/img/food/zucchini.svg'

type FridgeProps = ModalProps & {
  openGame: VoidFunction
  onClose: VoidFunction
  isFridgeEndModalVisible: boolean
  toggleFridgeEndModal: VoidFunction
}

enum RecipeItems {
  Cheese = 'cheese',
  Tomato = 'tomato',
  Zucchini = 'zucchini',
  Bread = 'bread',
}

const RECIPE = {
  [RecipeItems.Cheese]: 1,
  [RecipeItems.Bread]: 1,
  [RecipeItems.Tomato]: 2,
  [RecipeItems.Zucchini]: 1,
}

export const Fridge: React.FC<FridgeProps> = ({
  openGame,
  onClose,
  isFridgeEndModalVisible,
  toggleFridgeEndModal,
}) => {
  const [recipe, setRecipe] = useState({
    cheese: 0,
    tomato: 0,
    zucchini: 0,
    bread: 0,
  })

  const getTypeFilled = (itemName: RecipeItems) => {
    const value = recipe[itemName]

    if (value === 0) {
      return 'opacity'
    }

    if (value > 0 && value !== RECIPE[itemName]) {
      return 'filledPart'
    }

    if (value === RECIPE[itemName]) {
      return 'filled'
    }

    return 'opacity'
  }

  const isRecipeFinished = () => {
    let isFinished = true

    Object.keys(recipe).forEach((item) => {
      if (recipe[item] !== RECIPE[item]) {
        isFinished = false
      }
    })

    return isFinished
  }

  const handleItemClick = (itemName: RecipeItems) => {
    if (recipe[itemName] === RECIPE[itemName]) {
      return
    }

    setRecipe({
      ...recipe,
      [itemName]: recipe[itemName] + 1,
    })
  }

  useEffect(() => {
    if (isRecipeFinished()) {
      toggleFridgeEndModal()
    }
  }, [recipe])

  return (
    <Styled.Wrapper>
      <div className="container">
        <Styled.Fridge>
          <Styled.Content>
            <Styled.FridgeImage src={fridge} alt="холодильник" />
            <Styled.Bread
              src={bread}
              alt="хлеб"
              onClick={() => handleItemClick(RecipeItems.Bread)}
            />
            <Styled.Cheese
              src={cheese}
              alt="сыр"
              onClick={() => handleItemClick(RecipeItems.Cheese)}
            />
            <Styled.TomatoFirst
              src={tomato}
              alt="томат"
              onClick={() => handleItemClick(RecipeItems.Tomato)}
            />
            <Styled.TomatoSecond
              src={tomato}
              alt="томат"
              onClick={() => handleItemClick(RecipeItems.Tomato)}
            />
            <Styled.Zucchini
              src={zucchini}
              alt="кабачок"
              onClick={() => handleItemClick(RecipeItems.Zucchini)}
            />
          </Styled.Content>

          <Styled.Recipe>
            <img src={recipeImage} />
            <Styled.ItemRecipe filled={getTypeFilled(RecipeItems.Bread)}>
              <Styled.BreadRecipe src={bread} alt="хлеб" />
            </Styled.ItemRecipe>
            <Styled.ItemRecipe filled={getTypeFilled(RecipeItems.Cheese)}>
              <Styled.CheeseRecipe src={cheese} alt="сыр" />
            </Styled.ItemRecipe>
            <Styled.ItemRecipe filled={getTypeFilled(RecipeItems.Tomato)}>
              <Styled.TomatoRecipe src={tomato} alt="томат" />
            </Styled.ItemRecipe>
            <Styled.ItemRecipe filled={getTypeFilled(RecipeItems.Zucchini)}>
              <Styled.ZucchiniRecipe src={zucchini} alt="кабачок" />
            </Styled.ItemRecipe>
          </Styled.Recipe>

          <Styled.BackButton>
            <CrossButton onClick={onClose} />
          </Styled.BackButton>
        </Styled.Fridge>
      </div>

      <FridgeModal
        visible={isFridgeEndModalVisible}
        onClose={toggleFridgeEndModal}
        openGame={openGame}
      />
    </Styled.Wrapper>
  )
}
