import React from 'react'
import { Styled } from './styles'

type ProgressBarProps = {
  barWidth: string
}

export const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { barWidth } = props

  return (
    <Styled.ProgressBar>
      <Styled.Progress className="progress" style={{ width: barWidth }} />
    </Styled.ProgressBar>
  )
}
