import React from 'react'
import { Modal, Title, Text, Button } from 'src/components'
import benefits from 'src/shared/img/benefits.svg'
import check from 'src/shared/img/check.svg'
import miss from 'src/shared/img/miss.svg'
import { Styled } from './styles'

type EndGameModalProps = {
  visible: boolean
  restart: VoidFunction
  next: VoidFunction
  scoreGreen: number
  scoreRed: number
}

export const EndGameModal: React.FC<EndGameModalProps> = (props) => {
  const { visible, scoreGreen, scoreRed, restart, next } = props

  return (
    <Modal
      visible={visible}
      onClose={() => null}
      hideCloseButton={true}
      centered={true}
      footer={null}
    >
      <Title type="h1">Игра пройдена, поздравляем!</Title>
      <Styled.Text>
        <Text type="body1">
          Награда появится в вашем инвентаре
          <Styled.Icon src={benefits} width="31px" alt="" />
        </Text>
      </Styled.Text>

      <Styled.Score>
        <Styled.ScoreLeft>
          <img src={check}></img>
          <span>{scoreGreen}</span>
        </Styled.ScoreLeft>
        <Styled.ScoreRight>
          <img src={miss}></img>
          <span>{scoreRed}</span>
        </Styled.ScoreRight>
      </Styled.Score>

      <Styled.Controls>
        <Styled.Button>
          <Button type="primary" onClick={restart}>
            Играть заново
          </Button>
        </Styled.Button>
        <Styled.Button>
          <Button type="primary" onClick={next}>
            Далее
          </Button>
        </Styled.Button>
      </Styled.Controls>
    </Modal>
  )
}
