import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  height: 500px;
  margin: 0 20px;
  overflow: hidden;

  .canvas-wrapper {
    height: 500px;
    overflow: auto;

    @media (max-height: 550px) {
      height: calc(100vh - 60px);
    }
  }

  @media (max-height: 550px) {
    height: calc(100vh - 60px);
  }
`

export const Styled = {
  Wrapper,
}
