import { colors } from './'

export const textShadow = (color: string) => `
  color: ${color};
  text-shadow:
    2px 0 0 ${colors.WHITE},
    -2px 0 0 ${colors.WHITE},
    0 2px 0 ${colors.WHITE},
    0 -2px 0 ${colors.WHITE},
    1px 1px ${colors.WHITE},
    -1px -1px 0 ${colors.WHITE},
    1px -1px 0 ${colors.WHITE},
    -1px 1px 0 ${colors.WHITE};
`
