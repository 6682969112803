import { Scenario } from 'src/redux/ducks/map'
import { Hero } from 'src/types/hero'
import { ClipNames } from './types'

export const CLIP_NAMES_BY_HERO = {
  [Scenario.goHomeByBus]: {
    [Hero.men]: ClipNames.manGoHomeByBus,
    [Hero.women]: ClipNames.womenGoHomeByBus,
  },
  [Scenario.goHomeByCar]: {
    [Hero.men]: ClipNames.manVisitByCar,
    [Hero.women]: ClipNames.womenVisitByCar,
  },
  [Scenario.goHomeByTaxi]: {
    [Hero.men]: ClipNames.manGoHomeByTaxi,
    [Hero.women]: ClipNames.womenGoHomeByTaxi,
  },
  [Scenario.goHomeOnFoot]: {
    [Hero.men]: '',
    [Hero.women]: '',
  },
  [Scenario.goMarket]: {
    [Hero.men]: ClipNames.menGoMarket,
    [Hero.women]: ClipNames.womenGoMarket,
  },
  [Scenario.goPharmacy]: {
    [Hero.men]: ClipNames.manGoPharmacy,
    [Hero.women]: ClipNames.womenGoPharmacy,
  },
}

export const specialMeshNames = [
  'man_human_main_for_car',
  'women_human_main_for_car',
  'car_blue_for_main',
  'car_taxi_for_main',
  'man_human_main_for_taxi_bus',
  'women_human_main_for_taxi_bus',
  'bus_for_main_animation',

  'women_human_main_do_marketa',
  'man_human_main_do_marketa',

  'man_human_main_do_apteka',
  'women_human_main_do_apteka',
]

export const specialAnimateGroups = [
  {
    name: 'womenVisitByCar',
    elementNames: ['car_blue_for_main', 'women_human_main_for_car'],
  },
  {
    name: 'manVisitByCar',
    elementNames: ['car_blue_for_main', 'man_human_main_for_car'],
  },
  {
    name: 'menGoMarket',
    elementNames: ['man_human_main_do_marketa'],
  },
  {
    name: 'womenGoMarket',
    elementNames: ['women_human_main_do_marketa'],
  },
  {
    name: 'manGoPharmacy',
    elementNames: ['man_human_main_do_apteka'],
  },
  {
    name: 'womenGoPharmacy',
    elementNames: ['women_human_main_do_apteka'],
  },
  {
    name: 'manGoHomeByBus',
    elementNames: ['bus_for_main_animation', 'man_human_main_for_taxi_bus'],
  },
  {
    name: 'womenGoHomeByBus',
    elementNames: ['bus_for_main_animation', 'women_human_main_for_taxi_bus'],
  },
  {
    name: 'manGoHomeByTaxi',
    elementNames: ['car_taxi_for_main', 'man_human_main_for_taxi_bus'],
  },
  {
    name: 'womenGoHomeByTaxi',
    elementNames: ['car_taxi_for_main', 'women_human_main_for_taxi_bus'],
  },
]

export const Minuses = {
  [Scenario.goHomeByBus]: [
    'Платный проезд',
    'Сложная эпидемиологическая обстановка',
  ],
  [Scenario.goHomeByCar]: [
    'Необходимо обслуживать автомобиль',
    'Затраты на бензин',
  ],
  [Scenario.goHomeOnFoot]: [''],
  [Scenario.goHomeByTaxi]: [''],
  [Scenario.goPharmacy]: [
    'Трата времени на дорогу',
    'Социальная дистанция',
    'Масочный режим',
  ],
  [Scenario.goMarket]: [
    'Нести покупки',
    'Стоять в очереди',
    'Соблюдать меры безопасности',
  ],
}

export const ACTIVE_ELEMENTS = [
  'board_okko',
  'taxi_yellow_1',
  'taxi_yellow_3',
  'taxi_yellow_4',
  'taxi_yellow_5',
  'taxi_yellow_006',
  'board_city_mobil_1',
  'board_sber_market_1',
  'board_usual_market_1',
  'board_sber_mega_market_1',
  'board_sber_disk_1',
  'board_sber_health_1',
  'scooter_samocat_002',
  'scooter_samocat_003',
  'scooter_samocat_004',
  'scooter_samocat_005',
  'scooter_samocat_006',
  'scooter_samocat_007',
  'scooter_samocat_008',
  'scooter_samocat_009',
  'board_samokat',
  'board_sber_mobile_1',
  'board_sber_sound_1',
  'board_sber_prime_1',
  'board_sber_prime_1_(1)',
  'board_sber_box_1',
  'bike_del_club_1',
  'bike_del_club_002',
  'board_sber_devices_1',
  'board_sber_devices_2',
]
