import styled from 'styled-components'
import { CardStyle, CardStyleActive } from 'styles/card'

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  ${CardStyle}
  ${CardStyleActive}

  img {
    max-width: 30px;
    max-height: 30px;
  }
`

export const Styled = {
  Button,
}
