import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mapActions, Scenario } from 'src/redux/ducks/map'
import { GameActions } from 'src/redux/ducks/app'

import { useHistory } from 'react-router-dom'
import { appActions, heroSelector } from 'src/redux/ducks/app'

import { Modal, Title, Text, Card, CrossButton } from 'src/components'
import { ModalProps } from 'antd'
import { Styled } from './styles'
import shelves from './shelves-medication.png'
import man from 'src/shared/img/man-with-bag.svg'
import women from 'src/shared/img/women-with-bag.svg'
import courier from 'src/shared/img/сourier.svg'

import { Hero } from 'src/types'

import { Modals } from 'src/types/modals'

type MedicineModalProps = ModalProps & {
  onClose: VoidFunction
}

export const MedicineModal: React.FC<MedicineModalProps> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const hero = useSelector((state) => heroSelector(state))

  const { onClose } = props

  const onOrderClick = () => {
    onClose()
    dispatch(appActions.appModalOpen(Modals.pharmacy))
    dispatch(appActions.setLastAction(null))
  }

  const goToPharmacy = () => {
    onClose()
    history.push('/city')
    dispatch(mapActions.activateScenario(Scenario.goPharmacy))
    dispatch(appActions.setLastAction(Scenario.goPharmacy))
  }

  const modalClose = () => {
    onClose()
    dispatch(appActions.setLastAction(null))
  }

  return (
    <Modal {...props} centered={true} footer={null} onClose={modalClose}>
      <Title type="h1">Лекарства заканчиваются!</Title>
      <Text type="body2">Нужно купить новые. Какое действие выберете?</Text>
      <Card.List>
        <Card.Item onClick={goToPharmacy}>
          <Card.ItemImage>
            <img src={hero === Hero.men ? man : women} alt="" />
          </Card.ItemImage>
          <Text type="body1">Сходить в аптеку</Text>
        </Card.Item>
        <Card.Item onClick={onOrderClick}>
          <Card.ItemImage>
            <img src={courier} alt="" />
          </Card.ItemImage>
          <Text type="body1">Заказать</Text>
        </Card.Item>
      </Card.List>
    </Modal>
  )
}

type MedicineProps = ModalProps & {
  onClose: VoidFunction
  isMedicineEndModalVisible: boolean
  toggleMedicineEndModal: VoidFunction
}

export const Medicine: React.FC<MedicineProps> = ({
  onClose,
  isMedicineEndModalVisible,
  toggleMedicineEndModal,
}) => {
  return (
    <Styled.Wrapper>
      <div className="container">
        <Styled.Content>
          <Styled.Shelf onClick={toggleMedicineEndModal}>
            <img src={shelves} alt="полка с лекарствами" />
          </Styled.Shelf>
          <Styled.BackButton>
            <CrossButton onClick={onClose} />
          </Styled.BackButton>
        </Styled.Content>
      </div>

      <MedicineModal
        visible={isMedicineEndModalVisible}
        onClose={toggleMedicineEndModal}
      />
    </Styled.Wrapper>
  )
}
