import styled from 'styled-components'
import { TitleProps, AlignType, Type } from './types'

// TODO : разобраться почему без assertion ошибка с "symbol",
type PropsType<T> = {
  [key in T]?: string
  // [key in T as Type | AlignType]?: string
}

const fontSize: PropsType<Type> = {
  h1: '24',
  h2: '20',
  h3: '16',
}

const lineHeight: PropsType<Type> = {
  h1: '21',
  h2: '21',
  h3: '21',
}

const textAlign: PropsType<AlignType> = {
  center: 'center',
  right: 'right',
}

export const TitleStyled = styled.p<TitleProps>`
  margin-bottom: 12px;
  font-family: 'SBSansDisplay';
  font-size: ${(props) => fontSize[props.type as Type]}px;
  line-height: ${(props) => lineHeight[props.type as Type]}px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: ${(props) => textAlign[props.align as AlignType] || 'inherit'};
  color: inherit;
`
