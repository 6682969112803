import styled from 'styled-components'
import { colors } from 'src/styles/colors'

const Wrapper = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${colors.GRADIENT_BLUE};
`

const BackButton = styled.div`
  position: absolute;
  top: 8px;
  right: 16px;
`

export const Styled = {
  BackButton,
  Wrapper,
}
