import React, { useState, useCallback } from 'react'
import { PlayInfo, ChooseHero } from './components'
import { STEPS } from './types'

export const StartScreen: React.FC<{}> = () => {
  const [step, setStep] = useState<STEPS>(STEPS.welcome)

  const goNext = useCallback(() => {
    setStep(step + 1)
  }, [step])

  switch (step) {
    case STEPS.welcome:
    case STEPS.disclaimer: {
      return <PlayInfo goNext={goNext} currentStep={step} />
    }
    // case STEPS.benefits: {
    //   return <PlayInfo goNext={goNext} currentStep={step} />
    // }
    case STEPS.avatar: {
      return <ChooseHero />
    }
  }
}
