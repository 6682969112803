import { Modals } from 'src/types'

import deliveryClub from './components/Prime/delivery-club-mini.svg'
import eapteka from './components/Prime/eapteka.svg'
import okko from './components/Prime/okko-mini.svg'
import scooter from './components/Prime/scooter-mini.svg'
import sber from './components/Prime/sber.svg'

export const TITLES = {
  [Modals.okko]: 'Онлайн-кинотеатр',
  [Modals.citymobil]: 'Агрегатор на рынке такси',
  [Modals.pharmacy]: 'Сбер ЕАптека',
  [Modals.megamarket]: 'Маркетплейс',
  [Modals.market]: 'Доставка продуктов',
  [Modals.sound]: 'Стриминговый сервис',
  [Modals.scooter]: 'Доставка продуктов за 15 минут',
  [Modals.disk]: 'Безопасное облачное хранилище',
  [Modals.health]: 'Онлайн-консультации с врачами',
  [Modals.mobile]: 'мобильная связь от Сбербанка',
  [Modals.deliveryСlub]: 'Сервис №1 доставки еды из ресторанов',
  [Modals.prime]: 'Подписка со скидками на сервисы Сбера',
}

export const ITEMS = {
  [Modals.okko]: [
    'Возможность подключить к 1 аккаунту до 5 устройств',
    'Оффлайн просмотр',
    'Отсутствие рекламы и удобный поиск',
  ],
  [Modals.citymobil]: [
    'Комфортные поездки по доступным ценам',
    'Надежность и хороший клиентский сервис',
    'Постоянные скидки и акции',
    'Широкий выбор тарифов',
    'Кэшбек с поездок в классах Комфорт, Комфорт+, Бизнес',
    'Служба поддержки 24/7',
  ],
  [Modals.pharmacy]: [
    'Самый широкий ассортимент на рынке и подбор аналогов',
    'Низкие цены, постоянные акции и скидки',
    'Онлайн-оплата и оперативная доставка',
    'Приложение адаптировано для людей с ограниченными возможностями',
    'Круглосуточная поддержка покупателей',
  ],
  [Modals.megamarket]: [
    'Более 2 500 000 товаров от 8 000 интернет-магазинов',
    'Гарантия лучших цен и возможность купить товары в рассрочку',
    'Удобная и быстрая доставка, возможность получить товар в день заказа',
    'Кэшбек с возможностью оплаты до 50% стоимости следующих заказов',
    'Служба поддержки 24/7',
  ],
  [Modals.market]: [
    'Не нужно тратить время на дорогу до магазина',
    'Отсутствие очередей и необходимости нести тяжелые покупки',
    'Сертифицированные сборщики продуктов',
    'Все любимые магазины на расстоянии вытянутой руки',
    'Специальный раздел с акциями, цены как в магазине',
    'Доставка в день заказа в любой удобный двухчасовой интервал',
  ],
  [Modals.sound]: [
    '40 000 000 композиций, аудиокниг и подкастов в каталоге',
    'Рекомендации на основании предпочтений, действий и поведения слушателей',
    'Сториз о самых интересных музыкальных новостях',
    'Тексты песен с переводом',
    'Бесконечное радио по жанрам',
  ],
  [Modals.scooter]: [
    'Собственная торговая марка и производство',
    'Цены и выбор как в магазине у дома',
    'Свой склад в каждом районе',
    'Минимальная сумма доставки от 100 рублей (для Москвы и Санкт-Петербурга)',
    'Не надо носить тяжелые пакеты из магазина',
    'и планировать время для покупок',
  ],
  [Modals.disk]: [
    'Защита и сохранность данных пользователей',
    'Офлайн-режим работы с файлами',
    'Совместный доступ к файлам по ссылке',
    'Автоматическая синхронизация файлов на всех устройствах',
    'Возможность сканирования документов',
    'Лёгкий и удобный поиск файлов',
  ],
  [Modals.health]: [
    'Аналог очной консультации без траты на дорогу',
    'Сервис доступен круглосуточно любой точки мира',
    'Возможность обсудить любые вопросы',
    'Отсутствие талонов и очередей',
    'Выгодней, чем очные обращения в клинику',
    'Результаты и анализы хранятся в одном месте',
  ],
  [Modals.mobile]: [
    'Современный оператор для клиентов Сбера',
    'Всегда на связи с любимыми',
    'Высокий уровень безопасности данных',
    'Выгодная линейка тарифов, возможность собрать тариф для себя',
  ],
  [Modals.deliveryСlub]: [
    'Доставка любимых блюд из самых популярных ресторанов',
    'Доставка от 15 минут',
    'Постоянные акции и скидки',
  ],
}

export const PRIME_DATA = [
  [
    {
      key: Modals.okko,
      logo: okko,
      title: 'Okko',
      subtitle: 'Фильмы и сериалы',
    },
    {
      key: Modals.sound,
      logo: sber,
      title: 'СберЗвук',
      subtitle: '50 миллионов треков без рекламы',
    },
    {
      key: Modals.deliveryСlub,
      logo: deliveryClub,
      title: 'Delivery Club',
      subtitle: 'Скидка 10% на заказы из ресторанов',
    },
    {
      key: Modals.market,
      logo: sber,
      title: 'СберМаркет',
      subtitle: 'Бесплатная доставка продуктов из супермаркетов',
    },
    {
      key: Modals.scooter,
      logo: scooter,
      title: 'Самокат',
      subtitle: 'Скидка 7% на заказы продуктов с доставкой 15 минут',
    },
  ],
  [
    {
      key: Modals.pharmacy,
      logo: eapteka,
      title: 'СБЕР ЕАПТЕКА',
      subtitle: 'Бесплатная доставка товаров для здоровья',
    },
    {
      key: Modals.disk,
      logo: sber,
      title: 'СберДиск',
      subtitle: '30ГБ и скидка на расширение диска',
    },
    {
      key: Modals.megamarket,
      logo: sber,
      title: 'СберМегаМаркет',
      subtitle: 'Бесплатная доставка покупок',
    },
    {
      key: Modals.citymobil,
      logo: sber,
      title: 'Ситимобил',
      subtitle: 'Скидка 5% на «Эконом» и 10% на «Комфорт» и выше',
    },
    {
      key: Modals.mobile,
      logo: sber,
      title: 'СберМобайл',
      subtitle: 'Бесплатно 150 минут и 3ГБ в тарифе «Старт»',
    },
  ],
]
