import styled from 'styled-components'
import { textShadow } from 'src/styles/text'
import { colors } from 'src/styles/colors'
import crossCircle from 'src/shared/img/cross-circle.svg'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(392px - 32px);

  @media (max-height: 413px) {
    min-height: auto;
  }
`

export const Header = styled.div`
  margin-bottom: 13px;
`

export const Title = styled.div`
  ${textShadow(colors.GREEN)}

  p {
    margin-bottom: 0;
  }
`

export const Content = styled.div`
  margin-bottom: 30px;
`

export const List = styled.div``

export const Item = styled.div`
  margin: 0 -15px;

  padding: 24px 15px 22px;
  padding-left: 56px;

  text-align: left;

  background-image: url(${crossCircle});
  background-repeat: no-repeat;
  background-position: left 16px center;

  @media (max-height: 413px) {
    padding: 14px 15px 12px;
    padding-left: 56px;
  }

  &:nth-child(odd) {
    background-color: ${colors.WHITE};
  }

  p {
    margin-bottom: 0;
  }
`

export const Fotter = styled.div`
  margin-top: auto;
`

export const Styled = {
  Wrapper,
  Header,
  Title,
  Content,
  List,
  Item,
  Fotter,
}
