import styled from 'styled-components'
import { colors } from 'src/styles/colors'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${colors.GRADIENT_BLUE_LIGHT};
`

const Content = styled.div`
  display: flex;
  justify-content: center;

  img {
    max-height: calc(100vh - 100px);
  }
`

const BackButton = styled.div`
  position: absolute;
  top: 8px;
  right: 24px;
`

const Shelf = styled.div``

export const Styled = {
  Wrapper,
  Content,
  BackButton,
  Shelf,
}
