import { combineReducers } from '@reduxjs/toolkit'
import { appReducer } from './app'
import { gamesReducer } from './games'
import { mapReducer } from './map'

export const rootReducer = combineReducers({
  app: appReducer,
  games: gamesReducer,
  map: mapReducer,
})
