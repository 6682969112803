import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConfirmModal } from './components'
import { Title, BackButton } from 'src/components'
import { Styled } from './styles'
import card1 from './1.svg'
import card2 from './2.svg'
import card3 from './3.svg'
import card4 from './4.svg'
import card5 from './5.svg'
import manHand from 'src/shared/img/man-hand.svg'
import womenHand from 'src/shared/img/women-hand.svg'
import { useHistory } from 'react-router-dom'
import { appActions, heroSelector } from 'src/redux/ducks/app'
import { Modals } from 'src/types/modals'
import { Hero } from 'src/types'
import men from 'src/shared/img/men.svg'
import women from 'src/shared/img/women.svg'
import { Choice } from './types'

type PlanProps = {
  onClose: VoidFunction
}

export const Plan: React.FC<PlanProps> = (props) => {
  const dispatch = useDispatch()

  const history = useHistory()
  const hero = useSelector((state) => heroSelector(state))

  const [choice, setChoice] = useState<Choice | null>(null)

  const ConfirmCallback = useCallback(() => {
    switch (choice) {
      case Choice.Kitchen: {
        history.push('/city/home/kitchen')
        dispatch(appActions.appModalOpen(Modals.welcomeKitchen))
        break
      }
      case Choice.Bathroom: {
        history.push('/city/home/bathroom')
        dispatch(appActions.appModalOpen(Modals.welcomeBathroom))
        break
      }
      case Choice.LivingRoom: {
        history.push('/city/home/living-room')
        dispatch(appActions.appModalOpen(Modals.welcomeLivingRoom))
        break
      }
      case Choice.Bedroom: {
        history.push('/city/home/bedroom')
        dispatch(appActions.appModalOpen(Modals.welcomeBedroom))
        break
      }
      case Choice.Taxi: {
        history.push('/city')
        dispatch(appActions.appModalOpen(Modals.visit))
        break
      }
    }
  }, [choice])

  return (
    <Styled.Wrapper>
      <Styled.WrapperBG hero={hero} />
      <div className="container">
        <Styled.Title>
          <Title type="h1">Чем бы вы хотели заняться?</Title>
        </Styled.Title>
        <Styled.ImageWrapper>
          <Styled.Content>
            <Styled.Hand>
              <img src={hero === Hero.men ? manHand : womenHand} />
            </Styled.Hand>
            <Styled.ListWrapper hero={hero}>
              <Styled.List>
                <Styled.Item onClick={() => setChoice(Choice.Kitchen)}>
                  <img src={card1} />
                </Styled.Item>
                <Styled.Item onClick={() => setChoice(Choice.Bathroom)}>
                  <img src={card2} />
                </Styled.Item>
                <Styled.Item onClick={() => setChoice(Choice.LivingRoom)}>
                  <img src={card3} />
                </Styled.Item>
                <Styled.Item onClick={() => setChoice(Choice.Bedroom)}>
                  <img src={card4} />
                </Styled.Item>
                <Styled.Item onClick={() => setChoice(Choice.Taxi)}>
                  <img src={card5} />
                </Styled.Item>
              </Styled.List>
            </Styled.ListWrapper>
          </Styled.Content>
          <Styled.Hero src={hero === Hero.men ? men : women} hero={hero} />
        </Styled.ImageWrapper>
      </div>
      <Styled.BackButton>
        <BackButton onClick={() => history.push('/city')} />
      </Styled.BackButton>
      <ConfirmModal
        visible={choice}
        choice={choice}
        hideCloseButton={true}
        onClose={ConfirmCallback}
      />
    </Styled.Wrapper>
  )
}
