import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'src/redux'
import { isTaxiGameVisibleSelector } from 'src/redux/ducks/games'
import { Map, Controls } from './components'
import { RouteType } from 'types/router'
import { RouteWithSubRoutes } from 'src/components'
import { TaxiGame } from 'src/components'
import { heroSelector } from 'src/redux/ducks/app'
import history from 'src/history'

type AppScreenProps = {
  routes: RouteType[]
}

export const AppScreen: React.FC<AppScreenProps> = ({ routes }) => {
  const hero = useSelector((state: AppState) => heroSelector(state))
  if (hero === null) {
    history.push('/')
  }

  const isTaxiGameVisible = useSelector((state: AppState) =>
    isTaxiGameVisibleSelector(state),
  )

  return (
    <div>
      <Map />
      <Controls />
      {isTaxiGameVisible && <TaxiGame />}
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </div>
  )
}
