import React from 'react'
import { Styled } from './styles'

type BackButtonProps = {
  onClick: VoidFunction
}

export const BackButton: React.FC<BackButtonProps> = (props) => {
  return (
    <Styled.Button {...props}>
      {/* todo переделать на inline-svg */}
      <svg
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path d="M10.6001 22C10.2373 21.9982 9.8792 21.9187 9.54974 21.767C9.22028 21.6152 8.92716 21.3946 8.69009 21.12L1.69009 13.07C1.22671 12.5449 0.980262 11.8633 1.00069 11.1633C1.02111 10.4633 1.30688 9.79718 1.80009 9.3L9.37009 1.75C9.60441 1.51332 9.88314 1.32524 10.1903 1.19654C10.4975 1.06783 10.827 1.00104 11.1601 1C11.5223 1.00496 11.8794 1.08584 12.2084 1.23742C12.5374 1.389 12.831 1.60791 13.0701 1.88C13.528 2.40698 13.7691 3.08789 13.745 3.78558C13.7209 4.48327 13.4333 5.1459 12.9401 5.64L9.82009 8.75L20.5001 9.12C21.2162 9.13326 21.8977 9.43044 22.3946 9.94617C22.8916 10.4619 23.1634 11.1539 23.1501 11.87C23.1368 12.5861 22.8396 13.2676 22.3239 13.7645C21.8082 14.2615 21.1162 14.5333 20.4001 14.52L9.63009 14.15L12.5301 17.48C12.988 18.007 13.2291 18.6879 13.205 19.3856C13.1809 20.0833 12.8933 20.7459 12.4001 21.24C12.1654 21.4797 11.8854 21.6703 11.5764 21.8008C11.2674 21.9312 10.9355 21.999 10.6001 22Z" />
          <path
            d="M11.16 1.99976H11.21C11.4207 2.00911 11.6271 2.0619 11.8164 2.15483C12.0057 2.24776 12.1738 2.37881 12.31 2.53976C12.5986 2.8776 12.7505 3.31118 12.7357 3.75529C12.7209 4.19939 12.5405 4.62189 12.23 4.93976L7.49001 9.66976L20.49 10.1198C20.9087 10.1732 21.2929 10.3795 21.5686 10.6992C21.8443 11.0189 21.9921 11.4292 21.9834 11.8512C21.9747 12.2733 21.8102 12.6772 21.5216 12.9852C21.233 13.2933 20.8406 13.4837 20.42 13.5198H20.37L7.37001 13.0698L11.78 18.1398C12.0686 18.4776 12.2205 18.9112 12.2057 19.3553C12.1909 19.7994 12.0105 20.2219 11.7 20.5398C11.5529 20.6914 11.3757 20.8105 11.1797 20.8893C10.9837 20.9682 10.7733 21.005 10.5622 20.9975C10.3511 20.9899 10.1439 20.9382 9.95408 20.8456C9.76422 20.7529 9.5959 20.6215 9.46001 20.4598L2.46001 12.4098C2.16456 12.075 2.00572 11.6414 2.01498 11.195C2.02424 10.7486 2.20093 10.322 2.51001 9.99976L10.07 2.44976C10.2132 2.30683 10.3832 2.19351 10.5702 2.1163C10.7573 2.03909 10.9577 1.99949 11.16 1.99976ZM11.16 -0.000236168C10.2271 -0.0113952 9.32788 0.348284 8.66001 0.999764L1.10001 8.58976C0.42771 9.26406 0.0354663 10.1678 0.00207652 11.1194C-0.0313132 12.071 0.296624 13 0.920012 13.7198L7.92001 21.7698C8.23609 22.1378 8.62504 22.4364 9.06231 22.6466C9.49958 22.8568 9.97568 22.974 10.4606 22.9909C10.9454 23.0078 11.4285 22.9241 11.8794 22.7448C12.3302 22.5656 12.7391 22.2949 13.08 21.9498C13.7523 21.2755 14.1446 20.3717 14.1779 19.4201C14.2113 18.4685 13.8834 17.5395 13.26 16.8198L11.87 15.2198L20.25 15.5098H20.37C21.3513 15.527 22.2993 15.1537 23.0053 14.472C23.7114 13.7903 24.1178 12.8561 24.135 11.8748C24.1523 10.8935 23.779 9.94551 23.0973 9.23943C22.4156 8.53336 21.4813 8.127 20.5 8.10976L12.13 7.81976L13.61 6.33976C14.2823 5.66546 14.6746 4.76174 14.7079 3.81013C14.7413 2.85852 14.4134 1.92953 13.79 1.20976C13.4749 0.849215 13.09 0.556151 12.6587 0.348207C12.2273 0.140262 11.7584 0.0217373 11.28 -0.000236168H11.16Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="23.99" height="22.99" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Styled.Button>
  )
}
