import styled from 'styled-components'
import { colors } from 'styles/colors'
import { CardStyle } from 'styles/card'
import { textShadow } from 'styles/text'
import { Hero as HeroType } from 'src/types'
import men from 'src/shared/img/men.svg'
import women from 'src/shared/img/women.svg'

const Wrapper = styled.div`
  position: relative;
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 16px 0;
  background-image: ${colors.GRADIENT_NOT_BORING};
  text-align: left;

  // @media (min-height: 500px) {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  // }

  .container {
    // position: relative;
  }
`

const ImageWrapper = styled.div`
  //   @media (min-width: 500px) {
  //     display: flex;
  //   }
`

const WrapperBG = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
`

const BackButton = styled.div`
  position: absolute;
  top: 8px;
  right: 24px;
`

const Title = styled.div`
  margin-bottom: 19px;
  ${textShadow(colors.GREEN)}
`

const Content = styled.div`
  position: absolute;
  left: 50%;
  bottom: -95px;
  width: 445px;
  transform: translateX(-50%) translateX(-112px);

  @media (max-height: 413px) {
    width: 400px;
    transform: translateX(-50%) translateX(-142px);
  }

  @media (max-height: 374px) {
    width: 370px;
    transform: translateX(-50%) translateX(-162px);
  }

  @media (max-height: 340px) {
    width: 350px;
    transform: translateX(-50%) translateX(-162px);
  }

  @media (max-width: 736px) {
    left: 24px;
    transform: none;
  }

  // @media (min-height: 500px) {
  //   position: relative;
  //   top: auto;
  //   left: auto;
  //   transform: none;
  // }
`

const Hand = styled.div`
  img {
    width: 445px;

    @media (max-height: 413px) {
      width: 400px;
    }

    @media (max-height: 374px) {
      width: 375px;
    }

    @media (max-height: 340px) {
      width: 350px;
    }
  }
`

const Hero = styled.img`
  position: absolute;
  width: 250px;
  max-height: none;
  left: 50%;
  bottom: ${(props) => (props.hero === HeroType.men ? '-225' : '-365')}px;

  transform: ${(props) => {
    if (props.hero === HeroType.men) {
      return 'translateX(-50%) translateX(214px)'
    }

    if (props.hero === HeroType.women) {
      return 'scaleX(-1) translateX(-50%) translateX(32px)'
    }
  }};

  @media (max-height: 413px) {
    width: 230px;

    transform: ${(props) => {
      if (props.hero === HeroType.men) {
        return 'translateX(-50%) translateX(194px)'
      }

      if (props.hero === HeroType.women) {
        return 'scaleX(-1) translateX(-50%) translateX(32px)'
      }
    }};
  }

  @media (max-height: 374px) {
    width: 215px;
  }

  @media (max-height: 340px) {
    width: 210px;
  }

  // @media (min-height: 500px) {
  //   position: relative;
  //   top: auto;
  //   left: auto;
  //   transform: none;
  // }
`

const ListWrapper = styled.div`
  position: absolute;
  top: 27px;
  left: ${(props) => (props.hero === HeroType.women ? 73 : 68)}px;
  width: 100%;
  max-width: 120px;
  transform: skew(20deg);

  @media (max-height: 413px) {
    max-width: 106px;
    top: 25px;
    left: ${(props) => (props.hero === HeroType.women ? 60 : 62)}px;
  }

  @media (max-height: 374px) {
    max-width: 106px;
    top: 23px;
    left: ${(props) => (props.hero === HeroType.women ? 60 : 53)}px;
  }

  @media (max-height: 340px) {
    max-width: 89px;
    top: 23px;
    left: ${(props) => (props.hero === HeroType.women ? 60 : 56)}px;
  }
`

const List = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const Item = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 55px;
  height: 55px;
  margin-bottom: 10px;
  color: ${colors.GRAPHITE};
  text-align: center;
  ${CardStyle}

  @media (max-height: 413px) {
    width: 50px;
    height: 50px;
    margin-bottom: 6px;
  }

  @media (max-height: 374px) {
    width: 46px;
    height: 46px;
    margin-bottom: 6px;
  }

  @media (max-height: 340px) {
    width: 42px;
    height: 42px;
    margin-bottom: 6px;
  }

  &:nth-child(odd) {
    margin-right: 5px;
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    display: block;
    width: 100%;
    max-width: 80px;
  }

  p {
    margin-bottom: 0;
  }
`

export const Styled = {
  Wrapper,
  WrapperBG,
  BackButton,
  Title,
  ImageWrapper,
  Content,
  Hand,
  Hero,
  ListWrapper,
  List,
  Item,
}
