import { Scenario } from 'src/redux/ducks/map'
import { Minuses } from './constants'
import { Button, notification } from 'antd'

export class AnimationManager {
  scenario: Scenario

  constructor(scenario: Scenario) {
    this.scenario = scenario
  }
}

export class MapAnimationManager extends AnimationManager {
  interval: NodeJS.Timer | null
  notificationKeys: string[]

  constructor(scenario: Scenario) {
    super(scenario)
    this.interval = null
    this.notificationKeys = []
  }

  startBackgroundAnimation = () => {
    this.startAnimationByScenario()
  }

  stopBackgroundAnimation = () => {
    clearInterval(this.interval!)
    this.notificationKeys.forEach((key) => {
      notification.close(key)
    })
  }

  startAnimationByScenario = () => {
    switch (this.scenario) {
      case Scenario.goHomeByBus: {
        return this.showMinuses(Minuses[this.scenario], 40)
      }

      case Scenario.goHomeByCar: {
        return this.showMinuses(Minuses[this.scenario], 40)
      }

      case Scenario.goPharmacy: {
        return this.showMinuses(Minuses[this.scenario], 40)
      }

      case Scenario.goMarket: {
        return this.showMinuses(Minuses[this.scenario], 40)
      }
    }
  }

  showMinuses = (minuses: string[], duration: number) => {
    const interval = 5
    // const interval = duration / minuses.length

    const minusesCopy = minuses.slice()
    let minus = minusesCopy.shift()

    if (!minus) {
      return
    }

    this.showMinus(minus, interval)

    this.interval = setInterval(() => {
      minus = minusesCopy.shift()

      if (!minus) {
        return clearInterval(this.interval!)
      }

      this.showMinus(minus, interval)
    }, interval * 1000)
  }

  showMinus = (minus: string, duration: number) => {
    notification.open({
      key: minus,
      message: minus,
      duration: duration,
      closeIcon: null,
      placement: 'bottomLeft',
      className: 'test',
    })
    this.notificationKeys.push(minus)
  }
}
