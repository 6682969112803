import React, { createRef } from 'react'
import { Spin } from 'antd'
import { connect } from 'react-redux'
import { AppDispatch, AppState } from 'src/redux'
import { appActions, heroSelector } from 'src/redux/ducks/app'
import { mapActions, mapScenarioSelector, Scenario } from 'src/redux/ducks/map'

import { BackButton } from '../'

import { Styled } from './styles'
import { colors } from 'styles/colors'

import * as THREE from 'three'
import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {
  OrbitControls,
  MapControls,
} from 'three/examples/jsm/controls/OrbitControls'

import model from './SBMA_City_LightMap2.glb'
import modelAnimation from './sber_anim_only.gltf'
import modelEffects from './SBMA_City_FX.glb'

import lightMap from './LightMap.jpg'

import { Modals } from 'src/types/modals'

import { ClipManager, MapClipManager } from './utils'
import { MapAnimationManager } from './AnimationManager'
import {
  specialAnimateGroups,
  specialMeshNames,
  CLIP_NAMES_BY_HERO,
  ACTIVE_ELEMENTS,
} from './constants'

import { ClipNames } from './types'

const params = {
  clearColor: colors.SNOW,
}

type Game = {
  scene: THREE.Scene
  sceneAnimation: THREE.Scene
  renderer: THREE.WebGLRenderer
  camera: THREE.OrthographicCamera

  width: number
  height: number
  aspect: number
}

type MapComponentProps = {}

type MapComponentState = {
  modelLoaded: boolean
  animationModelLoaded: boolean
}

class MapComponent extends React.Component<
  MapComponentProps,
  MapComponentState
> {
  game: Game

  frameId: number | null

  d: number

  raycaster: THREE.Raycaster

  moved: boolean
  mouse: THREE.Vector2

  lastMove: any

  clock: THREE.Clock

  control: MapControls | null

  object: GLTF | null
  objectAnimation: GLTF | null

  model: THREE.Group | null
  modelAnimation: THREE.Group | null

  mixer: THREE.AnimationMixer | null
  clips: THREE.AnimationClip[] | null

  modelManager: THREE.LoadingManager
  animationModelManager: THREE.LoadingManager

  constructor(props: any) {
    super(props)

    this.game = {}

    this.frameId = null

    this.d = 5

    this.raycaster = new THREE.Raycaster()
    this.mouse = new THREE.Vector2()

    this.moved = false
    this.lastMove = null

    this.clock = new THREE.Clock()

    this.control = null
    this.objectAnimation = null
    this.object = null
    this.model = null
    this.modelAnimation = null
    this.mixer = null

    this.clips = null

    this.modelManager = new THREE.LoadingManager()
    this.animationModelManager = new THREE.LoadingManager()

    this.state = {
      modelLoaded: false,
      animationModelLoaded: false,
    }
  }

  private wrapper = createRef<HTMLDivElement>()

  componentDidMount() {
    this.init()

    this.addModel(model)
    this.addModelEffects(modelEffects)
    this.addModelAnimation(modelAnimation)

    this.start()
  }

  componentWillUnmount() {
    this.stop()
    this.wrapper.current!.removeChild(this.game.renderer.domElement)

    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('click', this.handleMapClick)
    window.removeEventListener('mouseup', this.handleMapClick)
    window.removeEventListener('touchend', this.handleMapClick)
    window.removeEventListener('touchstart', this.handleTouchStart)
    window.removeEventListener('touchmove', this.handleTouchMove)

    this.controls.removeEventListener('change', this.handleOrbitControlsChange)

    if (this.props.scenario) {
      this.stopScenario(scenario)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scenario === this.props.scenario) {
      return
    }

    if (prevProps.scenario) {
      this.stopScenario(prevProps.scenario)
    }

    if (this.props.scenario) {
      this.startScenario(this.props.scenario)
    }
  }

  stopScenario = (scenario: Scenario) => {
    const targetClipName = CLIP_NAMES_BY_HERO[scenario][this.props.hero]
    const clip = this.clips!.find((clip) => clip.name === targetClipName)

    if (!clip) {
      return
    }

    this.mixer!.clipAction(clip).stop()
    this.hideScenarioObjects(clip)
    this.animationManager.stopBackgroundAnimation()
  }

  startScenario = (scenario: Scenario) => {
    const targetClipName = CLIP_NAMES_BY_HERO[scenario][this.props.hero]
    const clip = this.clips!.find((clip) => clip.name === targetClipName)

    const mainClip = this.clips!.find((clip) => clip.name === ClipNames.Main)
    const mainAction = this.mixer!.clipAction(mainClip!)
    mainAction.reset()

    if (!clip) {
      return
    }

    this.animationManager = new MapAnimationManager(scenario)
    this.animationManager.startBackgroundAnimation()

    this.showScenarioObjects(clip)

    const action = this.mixer!.clipAction(clip!)
    action.play()
    action.clampWhenFinished = true
    action.loop = THREE.LoopOnce
  }

  showScenarioObjects = (clip: THREE.AnimationClip) => {
    const clipManager = new ClipManager()
    const objectNames = clipManager.getObjectNamesMapByClip(clip)

    this.modelAnimation!.traverse((n) => {
      if (n.isMesh) {
        if (objectNames[n.name]) {
          n.visible = true
        }
      }
    })
  }

  hideScenarioObjects = (clip: THREE.AnimationClip) => {
    const clipManager = new ClipManager()
    const objectNames = clipManager.getObjectNamesMapByClip(clip)

    this.modelAnimation!.traverse((n) => {
      if (n.isMesh) {
        if (objectNames[n.name]) {
          n.visible = false
        }
      }
    })
  }

  init = () => {
    this.game.width = this.wrapper.current!.clientWidth
    this.game.height = this.wrapper.current!.clientHeight
    this.game.aspect = this.game.width / this.game.height

    this.initScene()
    this.initCamera()
    this.initRenderer()

    this.addLight()

    this.addHandlers()
  }

  initScene = () => {
    const scene = new THREE.Scene()
    this.game.scene = scene

    const sceneAnimation = new THREE.Scene()
    this.game.sceneAnimation = sceneAnimation
  }

  initCamera = () => {
    const camera = new THREE.OrthographicCamera(
      -this.d * this.game.aspect,
      this.d * this.game.aspect,
      this.d,
      -this.d,
      -5,
      100,
    )

    camera.position.set(0, 0, 5)
    camera.zoom = 15
    camera.updateProjectionMatrix()

    this.game.camera = camera
  }

  initRenderer = () => {
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      powerPreference: 'high-performance',
    })

    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.setClearColor(params.clearColor)
    renderer.setSize(this.game.width, this.game.height)

    this.wrapper.current!.appendChild(renderer.domElement)

    this.game.renderer = renderer
  }

  addLight = () => {
    const light = new THREE.DirectionalLight(0xffa934, 1)
    const light2 = new THREE.DirectionalLight(0xffa934, 0.25)

    light.position.set(0.723, 1.117, 0.912)
    const alight = new THREE.AmbientLight(0x5274b3, 1)

    this.game.sceneAnimation.add(light)
    this.game.sceneAnimation.add(alight)

    this.game.scene.add(light2)
  }

  handleTouchStart = (e: TouchEvent) => {
    if (e.target.tagName !== 'CANVAS') {
      return
    }

    console.log('handleTouchStart')
    this.lastMove = e
  }

  handleTouchMove = (e: TouchEvent) => {
    if (e.target.tagName !== 'CANVAS') {
      return
    }

    console.log('handleTouchMove')
    this.lastMove = e
  }

  addHandlers = () => {
    window.addEventListener('resize', this.handleResize, false)
    window.addEventListener('click', this.handleMapClick, false)
    window.addEventListener('mouseup', this.handleMapClick, false)
    window.addEventListener('touchend', this.handleMapClick, false)
    window.addEventListener('touchstart', this.handleTouchStart)
    window.addEventListener('touchmove', this.handleTouchMove)

    this.modelManager.onLoad = () => {
      console.log('modelManager Loading complete!')
      this.setState({
        ...this.state,
        modelLoaded: true,
      })
    }

    this.animationModelManager.onLoad = () => {
      console.log('animationModelManager Loading complete!')
      this.setState({
        ...this.state,
        animationModelLoaded: true,
      })
    }

    const controls = new OrbitControls(
      this.game.camera,
      this.game.renderer.domElement,
    )

    controls.screenSpacePanning = true
    controls.enableRotate = false
    controls.enableZoom = false

    controls.touches = {
      ONE: THREE.TOUCH.PAN,
      TWO: THREE.TOUCH.DOLLY_PAN,
    }

    controls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      MIDDLE: THREE.MOUSE.PAN,
      RIGHT: THREE.MOUSE.PAN,
    }

    this.controls = controls

    controls.addEventListener('change', this.handleOrbitControlsChange)
  }

  handleOrbitControlsChange = (e) => {
    this.moved = true
  }

  handleResize = () => {
    this.game.width = this.wrapper.current!.clientWidth
    this.game.height = this.wrapper.current!.clientHeight
    this.game.aspect = this.game.width / this.game.height

    this.game.camera.left = -this.d * this.game.aspect
    this.game.camera.right = this.d * this.game.aspect
    this.game.camera.updateProjectionMatrix()

    this.game.renderer.setSize(this.game.width, this.game.height)
  }

  addModelAnimation = (model: any) => {
    const loader = new GLTFLoader(this.animationModelManager)

    loader.load(model, (object: GLTF) => {
      console.log('addModelAnimation object', object)

      const model = object.scene
      this.objectAnimation = object
      this.modelAnimation = model

      // model.rotation.y = 1.57

      model.rotation.x = 0.5
      model.rotation.y = -5.5

      model.traverse((n) => {
        if (n.isMesh) {
          if (specialMeshNames.includes(n.name)) {
            n.visible = false
          }

          n.callback = () => this.handleObjectClick(n)
        }
      })

      this.game.sceneAnimation.add(this.modelAnimation)

      this.initModelAnimations()
    })
  }

  addModel = (model: any) => {
    const loader = new GLTFLoader(this.modelManager)

    loader.load(model, (object: GLTF) => {
      console.log('addModel object', object)

      const model = object.scene
      this.object = object
      this.model = model

      model.rotation.y = 1.57

      model.rotation.x = 0.5
      model.rotation.y = -5.5
      this.bbox = new THREE.Box3().setFromObject(this.model!)

      const texture = new THREE.TextureLoader().load(lightMap)

      model.traverse((n) => {
        if (n.isMesh) {
          n.material.lightMap = texture
          n.callback = () => this.handleObjectClick(n)
        }
      })

      this.game.scene.add(model)
    })
  }

  addModelEffects = (model: any) => {
    const loader = new GLTFLoader(this.modelManager)

    loader.load(model, (object: GLTF) => {
      console.log('object', object)

      this.modelEffect = object.scene

      this.modelEffect.rotation.y = 1.57
      this.modelEffect.rotation.x = 0.5
      this.modelEffect.rotation.y = -5.5

      this.game.scene.add(this.modelEffect)
    })
  }

  // компонент, как и классы, порождены ленью дизайнера выделять отдельные анимации в клипы
  initModelAnimations = () => {
    this.mixer = new THREE.AnimationMixer(this.modelAnimation!)

    const mapClipManager = new MapClipManager()

    // анимаций для главной сцены
    const mainGroup = mapClipManager.getGroupExcludingNamesFromGroups({
      groups: specialAnimateGroups,
      animationClip: this.objectAnimation!.animations[0],
    })

    // анимации для сценариев
    const specialClips = mapClipManager.splitClipsByNames({
      groups: specialAnimateGroups,
      animationClip: this.objectAnimation!.animations[0],
    })

    // основная анимация (фоновая на сайте)
    const mainClip = mapClipManager.splitClipsByNames({
      groups: [mainGroup],
      animationClip: this.objectAnimation!.animations[0],
    })

    const allClips = [...specialClips, ...mainClip]

    this.clips = allClips

    this.startMainAnimation()

    this.mixer.addEventListener('finished', (e) => {
      this.props.removeScenario()
    })
  }

  startMainAnimation() {
    const clip = this.clips!.find((clip) => clip.name === ClipNames.Main)

    const action = this.mixer!.clipAction(clip!)
    action.play()
  }

  handleObjectClick = (mesh) => {
    console.log(mesh.name)

    const { openModal } = this.props

    if (ACTIVE_ELEMENTS.includes(mesh.name)) {
      if (this.animationManager) {
        this.animationManager.stopBackgroundAnimation()
      }
    }

    switch (mesh.name) {
      case 'board_okko': {
        return openModal(Modals.okko)
      }

      case 'taxi_yellow_1':
      case 'taxi_yellow_3':
      case 'taxi_yellow_4':
      case 'taxi_yellow_5':
      case 'taxi_yellow_006':
      case 'board_city_mobil_1': {
        return openModal(Modals.citymobil)
      }

      case 'board_sber_market_1':
      case 'board_usual_market_1': {
        return openModal(Modals.market)
      }

      case 'board_sber_mega_market_1': {
        return openModal(Modals.megamarket)
      }

      case 'board_sber_disk_1': {
        return openModal(Modals.disk)
      }

      case 'board_sber_health_1': {
        return openModal(Modals.health)
      }

      case 'scooter_samocat_002':
      case 'scooter_samocat_003':
      case 'scooter_samocat_004':
      case 'scooter_samocat_005':
      case 'scooter_samocat_006':
      case 'scooter_samocat_007':
      case 'scooter_samocat_008':
      case 'scooter_samocat_009':
      case 'board_samokat': {
        return openModal(Modals.scooter)
      }

      case 'board_sber_mobile_1': {
        return openModal(Modals.mobile)
      }

      case 'board_sber_sound_1': {
        return openModal(Modals.sound)
      }

      case 'board_sber_prime_1':
      case 'board_sber_prime_1_(1)': {
        return openModal(Modals.prime)
      }

      case 'board_sber_box_1': {
        // case 'board_usual_health_1': {
        return openModal(Modals.pharmacy)
      }

      case 'bike_del_club_1':
      case 'bike_del_club_002':
      case 'board_sber_devices_1':
      case 'board_sber_devices_2': {
        return openModal(Modals.deliveryСlub)
      }
    }
  }

  handleMapClick = (e) => {
    console.log('handleMapClick')
    if (e.target.tagName !== 'CANVAS') {
      return
    }

    if (!e.target.parentNode.classList.contains('map')) {
      return
    }

    if (this.moved) {
      this.moved = false
      return
    }

    e.preventDefault()

    const clientX =
      e.type === 'touchend'
        ? this.lastMove && this.lastMove.touches[0].clientX
        : e.clientX
    const clientY =
      e.type === 'touchend'
        ? this.lastMove && this.lastMove.touches[0].clientY
        : e.clientY

    this.mouse.x = (clientX / this.game.renderer.domElement.clientWidth) * 2 - 1
    this.mouse.y =
      -(clientY / this.game.renderer.domElement.clientHeight) * 2 + 1

    this.raycaster.setFromCamera(this.mouse, this.game.camera)

    const intersects = this.raycaster.intersectObjects(this.model!.children)
    const intersectsAnimation = this.raycaster.intersectObjects(
      this.modelAnimation!.children,
    )

    if (intersects.length > 0) {
      intersects[0].object.callback()
    }

    if (intersectsAnimation.length > 0) {
      intersectsAnimation[0].object.callback()
    }
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId!)
  }

  animate = () => {
    this.frameId = window.requestAnimationFrame(this.animate)

    const delta = this.clock.getDelta()

    if (this.mixer) {
      this.mixer.update(delta)
    }

    {
      if (this.model) {
        const bbox = this.bbox

        const factor = this.game.camera.zoom

        const x1 = this.game.camera.position.x + this.game.camera.left / factor
        const x1a = Math.max(x1, bbox.min.x / 2)
        let pos_x = x1a - this.game.camera.left / factor

        const x2 = pos_x + this.game.camera.right / factor
        const x2a = Math.min(x2, bbox.max.x + bbox.min.x / 2)
        pos_x = x2a - this.game.camera.right / factor

        const y1 =
          this.game.camera.position.y + this.game.camera.bottom / factor
        const y1a = Math.max(y1, bbox.min.y - bbox.min.y / 2)
        let pos_y = y1a - this.game.camera.bottom / factor

        const y2 = pos_y + this.game.camera.top / factor
        const y2a = Math.min(y2, bbox.max.y - bbox.max.y / 2 - 0.04)
        pos_y = y2a - this.game.camera.top / factor

        this.game.camera.position.set(pos_x, pos_y, this.game.camera.position.z)
        this.game.camera.lookAt(pos_x, pos_y, this.controls.target.z)
        this.controls.target.x = pos_x
        this.controls.target.y = pos_y
        this.controls.update()
      }
    }

    {
      if (this.modelEffect) {
        this.modelEffect.traverse((n: any) => {
          if (n.material && n.material.map) {
            const offsetX = n.material.map.offset.x
            n.material.map.offset.set(offsetX + 0.0008, 0)
          }
          console.log('')
        })
      }
    }

    this.renderScene()
  }

  renderScene() {
    this.game.renderer.autoClear = true

    this.game.renderer.render(this.game.scene, this.game.camera)
    this.game.renderer.autoClear = false

    this.game.renderer.render(this.game.sceneAnimation, this.game.camera)
  }

  render() {
    return (
      <>
        <Styled.Wrapper ref={this.wrapper} className="map" />
        {this.state.modelLoaded === false &&
          this.state.animationModelLoaded === false && (
            <Styled.Loader>
              <Spin size="large" />
            </Styled.Loader>
          )}
        <BackButton />
      </>
    )
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    openModal: (name: Modals) => dispatch(appActions.appModalOpen(name)),
    removeScenario: () => dispatch(mapActions.removeScenario()),
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    scenario: mapScenarioSelector(state),
    hero: heroSelector(state),
  }
}

export const Map = connect(mapStateToProps, mapDispatchToProps)(MapComponent)
