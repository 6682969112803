import { createSlice } from '@reduxjs/toolkit'
import { GamesInitialState } from './'

const initialState: GamesInitialState = {
  isTaxiGameVisible: false,
}

const GamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    showTaxiGame: (state) => ({
      ...state,
      isTaxiGameVisible: true,
    }),
    hideTaxiGame: (state) => ({
      ...state,
      isTaxiGameVisible: false,
    }),
  },
  extraReducers: {},
})

export const gamesReducer = GamesSlice.reducer
export const gamesActions = GamesSlice.actions
