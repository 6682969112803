import styled from 'styled-components'
import { colors } from 'src/styles/colors'
import { Hero as HeroType } from 'src/types'

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${colors.SNOW};
  color: ${colors.GRAPHITE};
`

const Container = styled.div`
  min-height: 100vh;
  margin: 0 auto;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  max-width: 406px;
  margin: 0 auto;

  @media (max-height: 373px) {
    max-width: 250px;
  }
`

const Hero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 213px;

  @media (max-height: 390px) {
    height: 150px;
  }

  img {
    width: ${(props) => (props.hero === HeroType.men ? 90 : 64)}px;
  }
`

const Title = styled.div`
  margin-bottom: 13px;
  color: ${colors.GRAPHITE};
`

const List = styled.div`
  width: 100%;
  margin-bottom: 30px;
`

export const Styled = {
  Wrapper,
  Container,
  Inner,
  Hero,
  Title,
  List,
}
