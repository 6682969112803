import type { AppState } from 'rdx/index'

export const isLoaderVisibleSelector = (state: AppState) =>
  state.app.isLoaderVisible

export const heroSelector = (state: AppState) => state.app.hero

export const appModalSelector = (state: AppState) => state.app.modal

export const appBenefitsSelector = (state: AppState) => state.app.benefits

export const appBenefitsCountSelector = (state: AppState) => {
  return Object.keys(state.app.benefits).length
}

export const lastActionSelector = (state: AppState) => state.app.lastAction
