import styled from 'styled-components'
import { colors } from 'src/styles/colors'

const Text = styled.div`
  margin-bottom: 68px;

  @media (max-height: 400px) {
    margin-bottom: 40px;
  }
`

const Icon = styled.img`
  position: relative;
  top: -2px;
  width: 31px;
  margin-left: 7px;
`

const Score = styled.div`
  margin-bottom: 11px;
  font-weight: 600;
  font-family: 'SBSansDisplay';
  line-height: 21px;
  color: ${colors.GREEN};

  span {
    &:first-child {
      margin-right: 17px;
      font-size: 80px;

      @media (max-height: 400px) {
        margin-right: 8px;
        font-size: 60px;
      }
    }

    &:last-child {
      font-size: 30px;

      @media (max-height: 400px) {
        font-size: 22px;
      }
    }
  }
`

const Controls = styled.div`
  display: flex;
  padding-top: 56px;

  @media (max-height: 400px) {
    padding-top: 30px;
  }
`

const Button = styled.div`
  width: calc(50% - 8px);

  &:first-child {
    margin-right: 16px;
  }

  button {
    width: 100%;
    min-width: auto;
  }
`

export const Styled = {
  Text,
  Icon,
  Score,
  Controls,
  Button,
}
