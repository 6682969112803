export const DISCLAIMER_TEXT = [
  {
    title: 'Самокат',
    ogrn: 'ОГРН 1137847232852',
    text: '192019, Санкт-Петербург, улица Седова, дом 11, литер А',
    link: 'https://samokat.ru',
  },
  {
    title: 'СберМегаМаркет',
    ogrn: 'ОГРН 1167746803180',
    text: '105082, Москва, Спартаковская площадь, дом 16/15, строение 6',
    link: 'https://sbermegamarket.ru',
  },
  {
    title: 'Сбермаркет',
    ogrn: 'ОГРН 1187746494980',
    text: '115035, Москва, Садовническая наб., дом 9, этаж 2, пом. III, ком. 7',
    link: 'https://sbermarket.ru',
  },
  {
    title: 'Окко',
    ogrn: 'ОГРН 1167847381130',
    text: 'Санкт-Петербург, улица Савушкина, д.126, литер Б, пом.53-Н',
    link: 'https://okko.tv',
  },
  {
    title: 'Сбердевайсы',
    ogrn: 'ОГРН 1197746592394',
    text: 'Москва, Кутузовский проспект, дом 32, кабинет 6.24',
    link: 'https://sberdevices.ru',
  },
  {
    title: 'Сберпрайм',
    ogrn: 'ОГРН 1117746689840',
    text: '121170, Москва, ул. Поклонная, 3, этаж 3, пом. 120',
    link: 'https://sberprime.sber.ru',
  },
  {
    title: 'Сберлогистика',
    ogrn: 'ОГРН: 1197746348458',
    text: '119334, Москва г, Вавилова ул, дом 24, корпус 1, помещение XXIX/3',
    link: 'https://sberlogistics.ru',
  },
  {
    title: 'Сберзвук',
    text:
      '107140, Москва г, вн.тер.г. муниципальный округ Красносельский, туп Большой Краснопрудный, д. 8/12, этаж 0, помещ. 2, ком. 4, офис 96',
    link: 'https://sber-zvuk.com',
  },
]
