import React from 'react'
import { Modal, Title, Text, Button } from 'src/components'
import benefits from 'src/shared/img/benefits.svg'
import { Styled } from './styles'

type EndGameModalProps = {
  visible: boolean
  score: number
  maxScore: number
  restart: VoidFunction
  next: VoidFunction
}

export const EndGameModal: React.FC<EndGameModalProps> = (props) => {
  const { visible, score, maxScore, restart, next } = props

  return (
    <Modal
      visible={visible}
      onClose={() => null}
      hideCloseButton={true}
      centered={true}
      footer={null}
    >
      <Title type="h1">Игра пройдена, поздравляем!</Title>
      <Styled.Text>
        <Text type="body1">
          Награда появится в вашем инвентаре
          <Styled.Icon src={benefits} width="31px" alt="" />
        </Text>
      </Styled.Text>

      <Styled.Score>
        <span>{score}</span> <span>/ {maxScore}</span>
      </Styled.Score>

      <Text type="body1">предметов собрано</Text>

      <Styled.Controls>
        <Styled.Button>
          <Button type="primary" onClick={restart}>
            Играть заново
          </Button>
        </Styled.Button>
        <Styled.Button>
          <Button type="primary" onClick={next}>
            Далее
          </Button>
        </Styled.Button>
      </Styled.Controls>
    </Modal>
  )
}
