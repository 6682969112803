import React from 'react'
import { Styled } from '../styles'
import { Button, Title, Text } from 'src/components'

type Props = {
  closeModal: VoidFunction
}

export const WelcomeLivingRoom: React.FC<Props> = (props) => {
  const { closeModal } = props

  return (
    <>
      <Styled.Title>
        <Title type="h1">Приветствую! Поможешь мне?</Title>
      </Styled.Title>
      <Text type="body2">
        Мы находимся у меня в гостиной. Можем посмотреть кино.
      </Text>
      <Text type="body2">
        Для начала нужно нажать на <Text type="body1">телевизор</Text> и выбрать
        тот раздел, который подходит тебе.
      </Text>
      <Text type="body2">Попробуем?</Text>
      <Styled.Button>
        <Button onClick={closeModal}>Начать игру</Button>
      </Styled.Button>
    </>
  )
}
