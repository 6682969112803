import React from 'react'
import { Router, Switch } from 'react-router-dom'
import { StartScreen, AppScreen, SberPortalScreen } from 'src/screens'
import { Plan, Home } from 'src/screens/AppScreen/components'
import {
  Bathroom,
  Bedroom,
  Kitchen,
  LivingRoom,
} from 'src/screens/AppScreen/components/Home/components'
import { RouteType } from 'types/router'
import { RouteWithSubRoutes } from 'src/components'
import history from 'src/history'

const routes: RouteType[] = [
  {
    path: '/',
    component: StartScreen,
    exact: true,
  },
  {
    path: '/city',
    component: AppScreen,
    routes: [
      {
        path: '/city/plan',
        component: Plan,
      },
      {
        path: '/city/home',
        component: Home,
        routes: [
          {
            path: '/city/home/bathroom',
            component: Bathroom,
          },
          {
            path: '/city/home/bedroom',
            component: Bedroom,
            routes: [
              {
                path: '/city/home/bedroom/sber-portal',
                component: SberPortalScreen,
              },
            ],
          },
          {
            path: '/city/home/kitchen',
            component: Kitchen,
          },
          {
            path: '/city/home/living-room',
            component: LivingRoom,
          },
        ],
      },
    ],
  },
]

export const AppRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </Router>
  )
}
