import styled from 'styled-components'
import { textShadow } from 'styles/text'
import { colors } from 'styles/colors'
import { CardStyle } from 'styles/card'

const Wrapper = styled.div``

const Title = styled.div`
  max-width: 315px;
  margin: 0 auto 27px;

  ${textShadow(colors.GREEN)}

  @media (max-height: 413px) {
    margin-bottom: 21px;
  }
`

const List = styled.div`
  margin-bottom: 44px;

  @media (max-height: 413px) {
    margin-bottom: 22px;
  }
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  padding: 8px;
  padding-right: 14px;
  ${CardStyle}

  @media (max-height: 413px) {
    margin-bottom: 8px;
    padding: 6px;
    padding-right: 12px;
  }
`

const ItemImage = styled.div`
  flex-grow: 0;
  width: 48px;
  margin-right: 8px;

  @media (max-height: 413px) {
    width: 38px;
  }
`

const ItemContent = styled.div`
  flex-grow: 1;
  text-align: left;
`

const ItemTitle = styled.div`
  p {
    margin-bottom: 0;

    @media (max-height: 413px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`

const ItemSubtitle = styled.div`
  p {
    margin-bottom: 0;

    @media (max-height: 413px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`

const ItemDuration = styled.div`
  align-self: top;
  flex-grow: 0;
`

export const Styled = {
  Wrapper,
  Title,
  List,
  Item: {
    Wrapper: ItemWrapper,
    Image: ItemImage,
    Content: ItemContent,
    Title: ItemTitle,
    Subtitle: ItemSubtitle,
    Duration: ItemDuration,
  },
}
