import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {} from 'react-redux'
import { AppState } from 'src/redux'
import { appModalSelector, appActions } from 'src/redux/ducks/app'
import { Button, Title, Text } from 'src/components'
import { Styled } from './styles'
import { Modals } from 'src/types'
import { ITEMS } from './mocks'

export const MinusChannel: React.FC<any> = (props) => {
  const dispatch = useDispatch()

  const openAdvantageModal = () => {
    dispatch(appActions.appModalOpen(Modals.okko))
  }

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title>
          <Title type="h2">Минусы обычного телеканала</Title>
        </Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Styled.List>
          {ITEMS.map((item) => (
            <Styled.Item key={item}>
              <Text type="body2">{item}</Text>
            </Styled.Item>
          ))}
        </Styled.List>
      </Styled.Content>
      <Styled.Fotter>
        <Button onClick={openAdvantageModal}>Узнать больше (?)</Button>
      </Styled.Fotter>
    </Styled.Wrapper>
  )
}
