import React from 'react'
import { useSelector } from 'react-redux'
import { heroSelector } from 'src/redux/ducks/app'
import { Styled } from './styles'
import { Modal, Button, Title, Text } from 'src/components'

type Props = {
  onClose: VoidFunction
  visible: boolean
}

export const WelcomeModal: React.FC<Props> = (props) => {
  const { onClose } = props
  const hero = useSelector((state) => heroSelector(state))

  return (
    <Modal
      {...props}
      hideCloseButton={true}
      centered={true}
      footer={null}
      className={'modal-welcome'}
    >
      <Styled.Wrapper hero={hero}>
        <Styled.Content>
          <Styled.Title>
            <Title type="h1">Поможешь мне поехать на такси?</Title>
          </Styled.Title>
          <Text type="body2">
            Пользоваться сервисом <Text type="body1">«Ситимобил»</Text> очень
            легко. Это так же легко, как сыграть в игру.
          </Text>

          <Styled.TextWithIcon>
            <Text type="body2">
              Нажимай на <Text type="body1">зелёный</Text> знак светофора{' '}
            </Text>
            <div className="img img-green"></div>
            {/* <img src={'http://via.placeholder.com/32x32'} /> */}
            <Text type="body2">чтобы машина поехала.</Text>
          </Styled.TextWithIcon>

          <Styled.TextWithIcon>
            <Text type="body2">
              По нажатию на <Text type="body1">красный</Text> знак{' '}
            </Text>
            <div className="img img-red"></div>
            {/* <img src={'http://via.placeholder.com/32x32'} /> */}
            <Text type="body2">она остановится.</Text>
          </Styled.TextWithIcon>
          <Styled.Button>
            <Button onClick={onClose}>Начать игру</Button>
          </Styled.Button>
        </Styled.Content>
      </Styled.Wrapper>
    </Modal>
  )
}
