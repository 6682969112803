import styled from 'styled-components'
import { CardStyle, CardStyleActive } from 'styles/card'

const Controls = styled.div`
  z-index: 10;
  position: fixed;
  bottom: 8px;
  right: 24px;
  display: flex;
`

const Item = styled.button`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin-right: 8px;
  outline: none;
  ${CardStyle}
  ${CardStyleActive}

  border: none;

  @media (max-height: 400px), (max-width: 700px) {
    width: 50px;
    height: 50px;
    padding: 3px;
  }

  &:last-child {
    margin-right: 0;
  }
`

export const Styled = {
  Controls,
  Item,
}
