import React from 'react'
import { Modal, Title, Button } from 'src/components'
import { ModalProps } from 'antd'
import { Choice } from '../../types'
import './style.scss'
import styled from 'styled-components'

const TitleStyled = styled.div`
  margin-bottom: 33px;
`

const Styled = {
  TitleStyled,
}

type ConfirmModalProps = ModalProps & {
  choice: Choice
  visible: boolean
  onClose: VoidFunction
}

const TEXT_MAP = {
  [Choice.Bathroom]: 'Принять лекарства',
  [Choice.Kitchen]: 'Приготовить еду',
  [Choice.Bedroom]: 'Послушать музыку',
  [Choice.Taxi]: 'Поехать в гости',
  [Choice.LivingRoom]: 'Посмотреть кино',
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const { choice, onClose } = props

  return (
    <Modal {...props} centered={true} footer={null} className="plan-confirm">
      <TitleStyled>
        <Title type="h1">{TEXT_MAP[choice]}</Title>
      </TitleStyled>
      <Button onClick={onClose}>Да</Button>
    </Modal>
  )
}
