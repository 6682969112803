import React, { createRef } from 'react'

import { CrossButton, ButtonWithIcon, Button } from 'src/components'

import { PdfViewer } from 'src/components/Modals/PdfViewer'

import { Styled } from './styles'
import { Spin } from 'antd'

import * as THREE from 'three'
import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import portal from './SM_SberPortal.glb'
import sberTop from './sberbox/sber_top.glb'
import sberBox from './sberbox/sbor_box_and_pult.glb'

import { colors } from 'styles/colors'

import history from 'src/history'

import { Icons } from 'src/types'

import portalPdf from './sberportal.pdf'
import sberTopPdf from './sberbox/prm-sberbox-top.pdf'
import sberboxPdf from './sberbox/sberbox.pdf'

enum ModelNames {
  Portal = 'Portal',
  SberTop = 'SberboxTop',
  SberBox = 'SberBox',
}

const MODELS = {
  [ModelNames.Portal]: {
    name: portal,
    format: 'glb',
  },
  [ModelNames.SberTop]: {
    name: sberTop,
    format: 'glb',
  },
  [ModelNames.SberBox]: {
    name: sberBox,
    format: 'glb',
  },
}

const FILES = {
  [ModelNames.Portal]: portalPdf,
  [ModelNames.SberTop]: sberTopPdf,
  [ModelNames.SberBox]: sberboxPdf,
}

type Game = {
  scene: THREE.Scene
  renderer: THREE.WebGLRenderer
  camera: THREE.Camera

  width: number
  height: number
  aspect: number
}

type SberPortalScreenState = {
  modelsLoaded: {
    [ModelNames.Portal]: THREE.Group | false
    [ModelNames.SberTop]: THREE.Group | false
    [ModelNames.SberBox]: THREE.Group | false
  }
  loadingModel: ModelNames | null
  currentModel: ModelNames | null
  pdfViewer: {
    visible: boolean
    src: string | null
  }
}

export class SberPortalScreen extends React.Component<
  {},
  SberPortalScreenState
> {
  game: Game
  frameId: number | null
  d: number
  model: THREE.Group | null
  modelManager: THREE.LoadingManager

  constructor(props: any) {
    super(props)

    this.game = {}
    this.frameId = null
    this.d = 1
    this.model = null
    this.modelManager = new THREE.LoadingManager()

    this.state = {
      modelsLoaded: {
        [ModelNames.Portal]: false,
        [ModelNames.SberTop]: false,
        [ModelNames.SberBox]: false,
      },
      loadingModel: null,
      currentModel: null,
      pdfViewer: {
        visible: false,
        src: null,
      },
    }
  }

  private wrapper = createRef<HTMLDivElement>()

  componentDidMount() {
    this.init()
    this.loadModel(MODELS[ModelNames.Portal], ModelNames.Portal)
    this.start()
  }

  componentWillUnmount() {
    this.stop()
    window.removeEventListener('resize', this.handleResize)
    this.wrapper.current!.removeChild(this.game.renderer.domElement)
  }

  componentDidUpdate(prevProps: any, prevState: SberPortalScreenState) {
    if (
      prevState.currentModel === this.state.currentModel ||
      prevState.currentModel === null
    ) {
      return
    }

    this.game.scene.remove(prevState.modelsLoaded[prevState.currentModel])

    if (!this.state.modelsLoaded[this.state.currentModel!]) {
      this.loadModel(MODELS[this.state.currentModel!], this.state.currentModel!)
    } else {
      this.game.scene.add(this.state.modelsLoaded[this.state.currentModel])
    }
  }

  init = () => {
    this.game.width = this.wrapper.current!.clientWidth
    this.game.height = this.wrapper.current!.clientHeight
    this.game.aspect = this.game.width / this.game.height

    this.initScene()
    this.initCamera()
    this.initRenderer()

    this.addLight()

    this.addHandlers()
  }

  initScene = () => {
    const scene = new THREE.Scene()
    this.game.scene = scene
  }

  initCamera = () => {
    const camera = new THREE.OrthographicCamera(
      -this.d * this.game.aspect,
      this.d * this.game.aspect,
      this.d,
      -this.d,
      -50,
      100,
    )

    this.game.scene.add(camera)

    camera.position.set(20, 20, 20)
    camera.lookAt(this.game.scene!.position)
    camera.zoom = 3
    camera.updateProjectionMatrix()

    this.game.camera = camera
  }

  initRenderer = () => {
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
    })

    renderer.setClearColor(colors.SNOW)
    renderer.setSize(this.game.width, this.game.height)
    renderer.outputEncoding = THREE.sRGBEncoding

    this.wrapper.current!.appendChild(renderer.domElement)

    this.game.renderer = renderer
  }

  addLight = () => {
    const light = new THREE.AmbientLight(0x404040)
    this.game.scene.add(light)
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1)
    this.game.scene.add(directionalLight)
  }

  loadModel = (model: any, name: ModelNames) => {
    const loader = new GLTFLoader(this.modelManager)

    this.setState({
      ...this.state,
      loadingModel: name,
    })

    if (model.format === 'glb') {
      loader.load(model.name, (object: GLTF) => {
        this.setState({
          ...this.state,
          modelsLoaded: {
            ...this.state.modelsLoaded,
            [name]: object.scene,
          },
        })

        object.scene.position.set(0, 0, 0)

        if (name === ModelNames.SberTop) {
          object.scene.scale.set(4, 4, 4)
        }

        if (name === ModelNames.SberBox) {
          object.scene.scale.set(10, 10, 10)
        }

        this.game.scene.add(object.scene)
      })
    }
  }

  addHandlers = () => {
    window.addEventListener('resize', this.handleResize, false)

    this.modelManager.onStart = (url, itemsLoaded, itemsTotal) => {
      console.log('modelManager start loading')
    }

    this.modelManager.onLoad = () => {
      this.setState({
        ...this.state,
        loadingModel: null,
        currentModel: this.state.loadingModel,
      })

      console.log('modelManager Loading complete!')
    }

    const controls = new OrbitControls(
      this.game.camera,
      this.game.renderer.domElement,
    )

    controls.screenSpacePanning = true
    controls.enableRotate = true
    controls.enableZoom = false
    controls.enablePan = false

    this.controls = controls
  }

  handleResize = (e) => {
    this.game.width = this.wrapper.current!.clientWidth
    this.game.height = this.wrapper.current!.clientHeight
    this.game.aspect = this.game.width / this.game.height

    this.game.camera.left = -this.d * this.game.aspect
    this.game.camera.right = this.d * this.game.aspect
    this.game.camera.updateProjectionMatrix()

    this.game.renderer.setSize(this.game.width, this.game.height)
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId!)
  }

  animate = () => {
    this.frameId = window.requestAnimationFrame(this.animate)
    this.renderScene()
  }

  renderScene = () => {
    this.game.renderer.render(this.game.scene, this.game.camera)
  }

  closePortal = () => {
    history.goBack()
  }

  changeModelNext = () => {
    let nextModel = null

    // да-да знаю, надо переделать если вдруг время будет
    if (this.state.currentModel === ModelNames.Portal) {
      nextModel = ModelNames.SberTop
    } else if (this.state.currentModel === ModelNames.SberTop) {
      nextModel = ModelNames.SberBox
    } else if (this.state.currentModel === ModelNames.SberBox) {
      nextModel = ModelNames.Portal
    }

    this.setState({
      ...this.state,
      currentModel: nextModel,
    })
  }

  changeModelBack = () => {
    let nextModel = null

    // да-да знаю, надо переделать если вдруг время будет
    if (this.state.currentModel === ModelNames.Portal) {
      nextModel = ModelNames.SberBox
    } else if (this.state.currentModel === ModelNames.SberBox) {
      nextModel = ModelNames.SberTop
    } else if (this.state.currentModel === ModelNames.SberTop) {
      nextModel = ModelNames.Portal
    }

    this.setState({
      ...this.state,
      currentModel: nextModel,
    })
  }

  openFile = () => {
    this.setState({
      ...this.state,
      pdfViewer: {
        visible: true,
        src: FILES[this.state.currentModel!],
      },
    })
  }

  closeFile = () => {
    this.setState({
      ...this.state,
      pdfViewer: {
        visible: false,
        src: null,
      },
    })
  }

  render() {
    return (
      <>
        <Styled.Wrapper ref={this.wrapper} id="gui" />
        <Styled.BackButton>
          <CrossButton onClick={this.closePortal} />
        </Styled.BackButton>
        <Styled.Controls>
          <Styled.Control>
            <ButtonWithIcon
              iconName={Icons.arrowLeft}
              onClick={this.changeModelBack}
            />
          </Styled.Control>
          <Styled.Control>
            <Button onClick={this.openFile}>Подбробнее</Button>
          </Styled.Control>
          <Styled.Control>
            <ButtonWithIcon
              iconName={Icons.arrowRight}
              onClick={this.changeModelNext}
            />
          </Styled.Control>
        </Styled.Controls>
        {this.state.loadingModel && (
          <Styled.Loader>
            <Spin size="large" />
          </Styled.Loader>
        )}
        {this.state.pdfViewer.visible && (
          <PdfViewer
            visible={this.state.pdfViewer.visible}
            src={this.state.pdfViewer.src}
            onClose={this.closeFile}
          />
        )}
      </>
    )
  }
}
