import React from 'react'
import { Styled } from '../styles'
import { Button, Title, Text } from 'src/components'

type Props = {
  closeModal: VoidFunction
}

export const WelcomeBathroom: React.FC<Props> = (props) => {
  const { closeModal } = props

  return (
    <>
      <Styled.Title>
        <Title type="h1">Приветствую! Поможешь мне?</Title>
      </Styled.Title>
      <Text type="body2">
        Мы находимся в моей ванной комнате. Мне нужно принять лекарства.
      </Text>
      <Text type="body2">
        Для начала нажми на <Text type="body1">полку с зеркалом</Text> и выбери
        любые лекарства.
      </Text>
      <Text type="body2">Справишься?</Text>
      <Styled.Button>
        <Button onClick={closeModal}>Начать игру</Button>
      </Styled.Button>
    </>
  )
}
